import {Component, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {AppStateService} from '../state/app-state.service';
import {Season} from '../seasons/model/season';
import {filter, finalize, takeUntil, tap} from 'rxjs/operators';
import {PitchCountService} from '../pitch-counts/pitch-count.service';
import {PitchCountAgeGroupReport} from '../pitch-counts/model/pitch-count-age-group-report';
import {Session} from '../auth/session/session';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnDestroy {
  readonly currentSession$: Observable<Session>;
  selectedSeason?: Season;
  pitchCountAgeGroupReport?: PitchCountAgeGroupReport;
  loading = false;
  private ngUnsubscribe = new Subject<void>();

  readonly faPencilAlt = faPencilAlt;

  constructor(
    private router: Router,
    private appState: AppStateService,
    private pitchCountService: PitchCountService,
  ) {
    this.currentSession$ = appState.currentSession$;
    this.initStateSubs();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initStateSubs(): void {
    this.appState.selectedSeason$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(val => val != null),
      )
      .subscribe(season => {
        this.selectedSeason = season;
        this.fetchReports();
      });
  }

  private fetchReports(): void {
    this.loading = true;
    this.loading = true;
    this.pitchCountService.fetchAgeGroupReport()
      .pipe(
        tap((report: PitchCountAgeGroupReport) => {
          this.pitchCountAgeGroupReport = report;
        }),
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.loading = false),
      )
      .subscribe();
  }
}
