import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {UiAlert} from '../ui-alert';
import {faCheckCircle, faExclamationCircle, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {BootstrapColorVariant} from '../../bootstrap/types';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnChanges {
  @Input() uiAlert?: UiAlert;
  @Output() closed = new EventEmitter<void>();
  icon?: IconDefinition;

  readonly selfClosingTimeout = 7000;
  readonly BootstrapColorVariant = BootstrapColorVariant;

  ngOnChanges(): void {
    if (this.uiAlert?.selfClosing) {
      setTimeout(() => this.onClosed(), this.selfClosingTimeout);
    }

    switch (this.uiAlert?.type) {
      case BootstrapColorVariant.SUCCESS: {
        this.icon = faCheckCircle;
        break;
      }
      case BootstrapColorVariant.WARNING: {
        this.icon = faExclamationCircle;
        break;
      }
      case BootstrapColorVariant.DANGER: {
        this.icon = faExclamationCircle;
        break;
      }
      default: {
        this.icon = undefined;
        break;
      }
    }
  }

  onClosed(): void {
    this.closed.emit();
    this.uiAlert = undefined;
  }
}
