<nav aria-label="Administration navigation">
  <div class="mb-3">
    <h2 class="h5" role="heading" aria-level="2">Season</h2>
    <ng-container *ngTemplateOutlet="navList;
				context:{navItems: seasonNavItems}"></ng-container>
  </div>

  <div class="mb-3">
    <h2 class="h5" role="heading" aria-level="2">Management</h2>
    <ng-container *ngTemplateOutlet="navList;
				context:{navItems: managementNavItems}"></ng-container>
  </div>
</nav>

<ng-template #navList let-navItems="navItems">
  <ul class="nav nav-pills flex-column">
    @for (navItem of navItems; track navItem.id) {
      <li class="nav-item">
        <a class="nav-link"
           [routerLink]="navItem.link"
           [ariaCurrentWhenActive]="'page'"
           [routerLinkActive]="'active'">
          @if (navItem.icon) {
            <fa-icon [icon]="navItem.icon"
                     [fixedWidth]="true"
                     aria-hidden="true"></fa-icon>
          }
          {{navItem.title}}
        </a>
      </li>
    }
  </ul>
</ng-template>
