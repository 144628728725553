<div class="row px-3 my-4">
  <div class="col-md-8">
    <app-loading-indicator [loading]="loading"></app-loading-indicator>

    @if (form) {
      <form [formGroup]="form"
            (ngSubmit)="submitClicked()"
            [attr.aria-label]="formTitle">
        <ng-content select="[form-controls]"></ng-content>
        <!-- Actions -->
        <div class="d-flex justify-content-between align-items-center mt-5">
          @if (deleteAllowed) {
            <div class="me-4">
              <app-button [color]="BootstrapColorVariant.DANGER"
                          [style]="ButtonStyle.OUTLINE"
                          [label]="'Delete'"
                          [icon]="faTrash"
                          [disabled]="deleteDisabled"
                          (clicked)="deleteClicked()"></app-button>
            </div>
          }
          <div class="flex-grow-1 text-end">
            <app-button [color]="BootstrapColorVariant.SECONDARY"
                        [style]="ButtonStyle.OUTLINE"
                        [label]="'Cancel'"
                        [classes]="['me-2']"
                        [disabled]="cancelDisabled"
                        (clicked)="cancelClicked()"></app-button>
            <app-button [icon]="faCheck"
                        [buttonType]="ButtonType.SUBMIT"
                        [classes]="['px-md-5']"
                        [label]="'Save'"
                        [loading]="loading"
                        [disabled]="submitDisabled"></app-button>
          </div>
        </div>
      </form>
    }
  </div>

  <div class="col-md-4">
    <ng-content select="[right-col]"></ng-content>
  </div>
</div>
