import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {faCheck, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ButtonStyle, ButtonType} from '../../../form/button/types';
import {BootstrapColorVariant} from '../../../bootstrap/types';

@Component({
  selector: 'app-entity-form-view',
  templateUrl: './entity-form-view.component.html',
})
export class EntityFormViewComponent {
  @Input() form?: UntypedFormGroup;
  @Input() formTitle = 'Edit';
  @Input() deleteAllowed = false;
  @Input() deleteDisabled = false;
  @Input() submitDisabled = false;
  @Input() cancelDisabled = false;
  @Input() loading = false;
  @Input() submitted = false;
  @Output() save = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  readonly faCheck = faCheck;
  readonly faTrash = faTrash;

  readonly BootstrapColorVariant = BootstrapColorVariant;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonType = ButtonType;

  submitClicked() {
    this.save.emit();
  }

  deleteClicked() {
    this.delete.emit();
  }

  cancelClicked() {
    this.cancel.emit();
  }
}
