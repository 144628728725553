import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {defaultCredentials} from '../credentials';
import {UiAlert} from '../../shared/ui-alert/ui-alert';
import {RuntimeTarget} from '../../../runtime-target';
import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {BootstrapColorVariant} from '../../shared/bootstrap/types';
import {ButtonType} from '../../shared/form/button/types';

enum FormControlName {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  credentials = defaultCredentials;
  loginForm!: UntypedFormGroup;
  error?: UiAlert;
  loading = false;
  submitted = false;

  private ngUnsubscribe = new Subject<void>();

  readonly FormControlName = FormControlName;
  readonly ButtonType = ButtonType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public runtimeTarget: RuntimeTarget
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      [FormControlName.EMAIL]: [this.credentials.email, [Validators.required, Validators.email]],
      [FormControlName.PASSWORD]: [this.credentials.password, [Validators.required]]
    });

    this.loginForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      ).subscribe(value => {
      this.credentials = {
        email: value.EMAIL,
        password: value.PASSWORD
      };
    });
  }

  login(): void {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }

    this.loading = true;
    this.authService.login(this.credentials)
      .pipe(
        finalize(() => this.loading = false),
      )
      .subscribe({
        complete: () => {
          this.router.navigateByUrl(this.nextUrl);
        },
        error: err => {
          this.error = {text: err, type: BootstrapColorVariant.WARNING};
          this.submitted = false;
          this.loginForm.controls[FormControlName.PASSWORD].reset();
          return err;
        },
      });
  }

  private get nextUrl(): string {
    return this.route.snapshot.queryParams.redirectUrl ?? '/home';
  }
}
