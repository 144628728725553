import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

export const getErrorMessage = (error: HttpErrorResponse | string): string => {
  let errorMessage = '';

  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error.error instanceof ErrorEvent) {
    errorMessage = `Error: ${error.error.message}`;
  } else if (error.status) {
    errorMessage = `Request failed with ${error.status} ${error.statusText}`;
  } else {
    errorMessage = error.message;
  }
  return errorMessage;
};

export const transformError = (error: HttpErrorResponse | string): Observable<never> => throwError(getErrorMessage(error));
