import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {RuntimeConfig} from './runtime-config';
import {RuntimeTarget} from './runtime-target';

// Values are set via Webpack
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
declare const __TARGET_PATH__: string;
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
declare const __BUILD_PATH__: string;

if (environment.production) {
  enableProdMode();
}

fetch(__BUILD_PATH__).then(async buildRes => {
  const config = await buildRes.json();
  fetch(__TARGET_PATH__).then(async targetRes => {
    const target = await targetRes.json();
    const providers = [
      {provide: RuntimeConfig, useValue: config},
      {provide: RuntimeTarget, useValue: target},
    ];
    platformBrowserDynamic(providers)
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
});
