import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {NumberUtils} from '../../utils/number-utils';
import {StringUtils} from '../../utils/string-utils';
import {Identity} from '../entity/model/types';
import {ButtonSize, ButtonStyle} from '../form/button/types';
import {BootstrapColorVariant} from '../bootstrap/types';
import {PageAndSort, SortDir} from './page-and-sort';
import {ButtonOptions} from '../form/button/button-options';

export interface DataTableCell {
  id: string;
  label: string;
  value?: string;
  sortValue?: string;
  sortable?: boolean;
  color?: string;
  bold?: boolean;
  expandWidth?: boolean;
  align?: 'left' | 'right' | 'center';
  class?: string | string[];
  routerLink?: string | string[];
  actionButton?: ButtonOptions;
}

export interface DataTableRow {
  id: Identity;
  cells: DataTableCell[];
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnChanges {
  public static readonly DEFAULT_PAGE_SIZE = 20;
  public static readonly DEFAULT_CURRENT_PAGE = 1;

  @Input() rows: DataTableRow[] = [];
  @Input() caption = 'Data list';
  @Input() pageAndSort: PageAndSort = {page: DataTableComponent.DEFAULT_CURRENT_PAGE};
  @Input() pageSize = DataTableComponent.DEFAULT_PAGE_SIZE;
  @Input() nonfluid = false;
  @Input() small = false;

  @Output() pageAndSortChanged = new EventEmitter<PageAndSort>();

  pageRows: DataTableRow[] = [];

  readonly BootstrapColorVariant = BootstrapColorVariant;
  readonly ButtonSize = ButtonSize;
  readonly ButtonStyle = ButtonStyle;

  ngOnChanges(): void {
    this.updatePageRows();
  }

  onPageClicked(newPage: number) {
    this.pageAndSortChanged.emit({
      page: newPage,
      sort: this.pageAndSort.sort,
      sortDir: this.pageAndSort.sortDir
    });
  }

  updatePageRows(): void {
    this.sortRows();

    const start = (this.pageAndSort.page - 1) * this.pageSize;
    const end = Math.min(this.rows.length, this.pageAndSort.page * this.pageSize);
    this.pageRows = this.rows.slice(start, end);
  }

  onSortClicked(newSortColumnId: string): void {
    this.pageAndSortChanged.emit({
      page: DataTableComponent.DEFAULT_CURRENT_PAGE,
      sort: newSortColumnId,
      sortDir: this.getNewSortDir(newSortColumnId)
    });
  }

  private getNewSortDir(newSortColumnId: string): SortDir {
    if (newSortColumnId === this.pageAndSort.sort) {
      // Same column: Reverse direction
      if (this.pageAndSort.sortDir == null || this.pageAndSort.sortDir === SortDir.DESC) {
        return SortDir.ASC;
      }
      return SortDir.DESC;
    }

    // New column: reset direction
    return SortDir.ASC;
  }

  hasRows(): boolean {
    return this.rows?.length > 0;
  }

  private sortRows(): void {
    if (StringUtils.isNotBlank(this.pageAndSort.sort) && this.hasRows()) {
      const sortColIndex = this.getSortColIndex();
      if (sortColIndex > -1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.rows.sort((a: any, b: any) => this.compareRows(a, b, sortColIndex));
      }
    }
  }

  private compareRows(a: DataTableRow, b: DataTableRow, sortColIndex: number): number {
    if (this.pageAndSort.sortDir === SortDir.DESC) {
      const aOrig = a;
      a = b;
      b = aOrig;
    }

    const aCell = a.cells[sortColIndex];
    const aValue = aCell.sortValue ?? aCell.value ?? '';
    if (StringUtils.isBlank(aValue)) {
      // Nulls last
      return 1;
    }

    const bCell = b.cells[sortColIndex];
    const bValue = bCell.sortValue ?? bCell.value ?? '';
    if (StringUtils.isBlank(bValue)) {
      // Nulls last
      return -1;
    }

    const getCollatorOptions = (): Intl.CollatorOptions => {
      if ((NumberUtils.isNumeric(aValue) && NumberUtils.isNumeric(bValue))) {
        return {numeric: true};
      }
      // Strings: case-insensitive
      return {sensitivity: 'base'};
    };

    return aValue.localeCompare(bValue, undefined, getCollatorOptions());
  }

  private getSortColIndex(): number {
    if (!this.hasRows() || StringUtils.isBlank(this.pageAndSort.sort)) {
      return -1;
    }

    return this.rows[0].cells.findIndex(c => c.id === this.pageAndSort.sort);
  }

  getAriaSort(columnId: string): string {
    if (this.pageAndSort.sort === columnId && this.pageAndSort.sortDir != null) {
      return this.pageAndSort.sortDir === SortDir.ASC ? 'ascending' : 'descending';
    }
    return 'none';
  }
}
