import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PitchCountAgeGroupReport} from '../../../pitch-counts/model/pitch-count-age-group-report';
import {DataTableComponent, DataTableRow} from '../../../shared/data-table/data-table.component';
import {PageAndSort} from '../../../shared/data-table/page-and-sort';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'app-pitch-count-report',
  templateUrl: './pitch-count-report.component.html',
})
export class PitchCountReportComponent implements OnChanges {
  @Input() report?: PitchCountAgeGroupReport;

  readonly reportTitle = 'PITCH COUNTS';
  readonly decimalFormat = '1.1-1';
  readonly pageAndSort: PageAndSort = {page: DataTableComponent.DEFAULT_CURRENT_PAGE};
  rows: DataTableRow[] = [];

  constructor(private decimalPipe: DecimalPipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initTableRows();
  }

  private initTableRows(): void {
    this.rows = [];

    if (this.report == null) {
      return;
    }

    this.report.ageGroups.forEach(item => {
      this.rows.push({
        id: `${item.ageGroup}`,
        cells: [
          {
            id: 'ageGroup',
            label: 'Age Group',
            value: `${item.ageGroup}U`,
            bold: true,
            align: 'center',
          },
          {
            id: 'pitchesTotal',
            label: 'Total',
            value: `${item.pitchesTotal}`,
            align: 'right',
          },
          {
            id: 'pitchesPerGameAvg',
            label: 'Per-Game Avg',
            value: this.decimalPipe.transform(item.pitchesPerGameAvg, this.decimalFormat) ?? '',
            align: 'right',
          },
          {
            id: 'pitchesPerPlayerPerGameAvg',
            label: 'Per-Player Avg',
            value: this.decimalPipe.transform(item.pitchesPerPlayerPerGameAvg, this.decimalFormat) ?? '',
            align: 'right',
            class: item.pitchesPerPlayerPerGameAvg > item.dailyLimit ? 'text-danger' : undefined,
          },
          {
            id: 'dailyLimit',
            label: 'Daily Limit',
            value: `${item.dailyLimit}`,
            align: 'right',
          },
        ],
      });
    });
  }
}
