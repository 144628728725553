<div class="dropdown"
     ngbDropdown
     placement="bottom-end">
  <button type="button"
          id="{{controlId}}"
          class="btn"
          [ngClass]="getClasses()"
          ngbDropdownToggle
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false">
    @if (iconDef) {
      <fa-icon [icon]="iconDef" aria-hidden="true"></fa-icon>
    }
    {{label}}
  </button>

  <ul class="dropdown-menu" ngbDropdownMenu [attr.aria-labelledby]="controlId">
    @for (teamRole of teamRoles$ | async; track teamRole.teamRoleId) {
      <li>
        <button ngbDropdownItem
                type="button"
                (click)="onClick(teamRole)">
          Add as <span class="fw-semibold">{{teamRole.label}}</span>
        </button>
      </li>
    }
  </ul>
</div>
