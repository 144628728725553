import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() entityNamePlural = '';
  @Input() id = '';
  @Output() valueChanged = new EventEmitter<string>();

  readonly debounceMS = 200;
  readonly valueChangeSubject: Subject<string> = new Subject<string>();
  protected ngUnsubscribe = new Subject<void>();

  ngOnInit(): void {
    this.valueChangeSubject
      .pipe(
        debounceTime(this.debounceMS),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((value: string) => this.valueChanged.emit(value));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearchEntered(value: string) {
    this.valueChangeSubject.next(value);
  }
}
