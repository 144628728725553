export class RuntimeTarget {
  appKey = '';
  appName = '';
  consoleName = '';
  redirects = {
    iosUrl: '',
    androidUrl: '',
    defaultUrl: '',
  };
  theme = {
    logoUrl: '',
  };
}

export interface RedirectUrls {
  iosUrl: string;
  androidUrl: string;
  defaultUrl: string;
}

export interface Theme {
  logoUrl: string;
}
