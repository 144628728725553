import {Pipe, PipeTransform} from '@angular/core';
import {WinLossRecord} from 'src/app/teams/model/win-loss-record';

@Pipe({
  name: 'winLossRecordString'
})
export class WinLossRecordPipe implements PipeTransform {

  transform(record: WinLossRecord | null | undefined): string {
    if (record == null) {
      return '';
    }
    return `${record.wins}-${record.losses}-${record.ties}`;
  }

}
