import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  Self,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormGroup
} from '@angular/forms';
import {Subject} from 'rxjs';
import {isEmpty} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {ValidationMessage} from './validation-message';
import {valueChangedAndNotFirst} from '../../utils/change-utils';
import {ValidationErrorKey} from './validation-error-key';
import {formControlDoIfPresent} from '../../utils/ng-rx-form.utils';

/**
 * Use in Reactive FormControl <input> to display validation errors below the input
 * and to set the FormControl's class to indicate current status.
 */
@Directive({
  selector: '[appFieldError]'
})
export class FieldErrorDirective implements OnInit, OnChanges, OnDestroy {
  @Input() fieldLabel = 'Value';
  @Input() ngForm!: NgForm;
  @Input() submitted = false;
  // Optional overrides/additions to messages
  @Input() messages?: ValidationMessage[];
  // Optional names of FormControls to refresh validation when this control is updated
  @Input() linkedControls?: string[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private errorElement?: any;
  private ngUnsubscribe = new Subject<void>();

  readonly errorElementClass = 'invalid-feedback';
  readonly invalidControlClass = 'is-invalid';

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              @Self() private ngControl: NgControl,
              private controlContainer: ControlContainer
  ) {
  }

  ngOnInit(): void {
    this.initStatusChangeSub();
    this.initValueChangeSub();
  }

  private initStatusChangeSub(): void {
    if (this.ngControl.statusChanges) {
      this.ngControl.statusChanges
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(() => this.updateValidation());
    }
  }

  private initValueChangeSub(): void {
    if (this.linkedControls && this.ngControl.valueChanges) {
      this.ngControl.valueChanges
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(() => {
          this.linkedControls?.forEach(linkedControl => {
            formControlDoIfPresent(this.form, linkedControl, (ctrl => {
              ctrl.updateValueAndValidity({emitEvent: false});
              ctrl.markAsDirty();
              ctrl.markAsTouched();
            }));
          });
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (valueChangedAndNotFirst(changes.submitted) || valueChangedAndNotFirst(changes.message)) {
      this.updateValidation();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.removeErrorElement();
  }

  private unsubscribe(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get form(): UntypedFormGroup | null {
    return this.controlContainer.formDirective ?
      (this.controlContainer.formDirective as FormGroupDirective).form : null;
  }

  get formControl(): AbstractControl | null {
    return this.ngControl.control;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get parentNode(): any {
    return this.renderer.parentNode(this.el.nativeElement);
  }

  get valid(): boolean {
    return Boolean(this.ngControl.disabled) ||
      Boolean(this.ngControl.valid) ||
      (Boolean(this.ngControl.untouched) && !this.submitted);
  }

  @HostListener('blur')
  onBlur(): void {
    this.updateValidation();
  }

  private updateValidation(): void {
    this.removeErrorElement();

    if (this.valid) {
      this.renderer.removeClass(this.el.nativeElement, this.invalidControlClass);
    } else {
      this.renderer.addClass(this.el.nativeElement, this.invalidControlClass);

      // Wrap individual error-message elements in the errorElement
      const errorEls = this.getErrorMessageElements();
      if (!isEmpty(errorEls)) {
        this.createErrorElement();
        this.addErrorMessageElementsToErrorElement(errorEls);
        this.addErrorElementToDOM();
      }
    }
  }

  private addErrorMessageElementsToErrorElement(errorEls: any[]): void {
    errorEls.forEach(errorEl =>
      this.renderer.appendChild(this.errorElement, errorEl)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getErrorMessageElements(): any[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorEls: any[] = [];
    if (this.formControl == null || this.formControl.errors == null) {
      return errorEls;
    }

    Object.keys(this.formControl.errors).forEach(errorKey => {
      const errorEl = this.renderer.createElement('div');
      const text = this.renderer.createText(this.getErrorMessage(errorKey));
      this.renderer.appendChild(errorEl, text);

      errorEls.push(errorEl);
    });

    return errorEls;
  }

  private getErrorMessage(errorKey: string): string {
    return this.getOverrideErrorMessage(errorKey) ?? this.getDefaultErrorMessage(errorKey);
  }

  private getOverrideErrorMessage(errorKey: string): string | undefined {
    if (this.messages == null) {
      return;
    }
    return this.messages.find(m => m.errorKey === errorKey)?.message;
  }

  private getDefaultErrorMessage(errorKey: string): string {
    switch (errorKey) {
      case ValidationErrorKey.REQUIRED:
        return `${this.fieldLabel} is required.`;
      case ValidationErrorKey.MAX_LENGTH:
        return `${this.fieldLabel} cannot exceed ${this.formControl?.getError(errorKey)?.requiredLength ?? 100} characters.`;
      case ValidationErrorKey.MIN_LENGTH:
        return `${this.fieldLabel} must be at least ${this.formControl?.getError(errorKey)?.requiredLength ?? 2} characters.`;
      case ValidationErrorKey.MAX:
        return `${this.fieldLabel} cannot exceed ${this.formControl?.getError(errorKey)?.max}.`;
      case ValidationErrorKey.MIN:
        return `${this.fieldLabel} must be at least ${this.formControl?.getError(errorKey)?.min}.`;
      case ValidationErrorKey.EMAIL:
        return `A valid ${this.fieldLabel} is required.`;
      case ValidationErrorKey.INVALID_DATE_RANGE:
        return `A valid date range is required.`;
      default:
        return `A valid ${this.fieldLabel} is required.`;
    }
  }

  private createErrorElement(): void {
    this.errorElement = this.renderer.createElement('div');
    this.renderer.addClass(this.errorElement, this.errorElementClass);
  }

  private addErrorElementToDOM(): void {
    this.renderer.appendChild(this.parentNode, this.errorElement);
  }

  private removeErrorElement(): void {
    if (this.errorElement) {
      this.renderer.removeChild(this.parentNode, this.errorElement);
      this.errorElement = undefined;
    }
  }
}
