import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {FooterComponent} from './layout/footer/footer.component';
import {HeaderComponent} from './layout/header/header.component';
import {NavbarComponent} from './layout/navbar/navbar.component';
import {AuthHttpInterceptor} from './auth/auth-http-interceptor';
import {HomeComponent} from './home/home.component';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from './shared/shared.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SeasonPickerComponent} from './layout/season-picker/season-picker.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {NgbCustomDateParserFormatter} from './shared/datepicker/ngb-custom-date-parser-formatter';
import {DateFormat} from './utils/date-utils';
import {NgbCustomDateAdapter} from './shared/datepicker/ngb-custom-date-adapter';
import {PasswordResetComponent} from './auth/password-reset/password-reset.component';
import {AppLayoutComponent} from './layout/app-layout/app-layout.component';
import {MobileAppRedirectComponent} from './mobile-app-redirect/mobile-app-redirect.component';
import {InvitationAcceptComponent} from './auth/password-reset/invitation-accept/invitation-accept.component';
import {AppTitleStrategy} from './app-title-strategy';
import {TitleStrategy} from '@angular/router';
import {GameEditGrantHelpComponent} from './game-edit-grants/game-edit-grant-help/game-edit-grant-help.component';
import {PitchCountReportComponent} from './home/report/pitch-count-report/pitch-count-report.component';
import {DecimalPipe} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    NavbarComponent,
    HomeComponent,
    SeasonPickerComponent,
    SidebarComponent,
    PasswordResetComponent,
    AppLayoutComponent,
    MobileAppRedirectComponent,
    InvitationAcceptComponent,
    GameEditGrantHelpComponent,
    PitchCountReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    FontAwesomeModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new NgbCustomDateParserFormatter(DateFormat.MMDDYYYY_SLASH)
    },
    {
      provide: NgbDateAdapter,
      useFactory: () => new NgbCustomDateAdapter(DateFormat.ISO_8601)
    },
    {
      provide: TitleStrategy,
      useClass: AppTitleStrategy
    },
    DecimalPipe,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
