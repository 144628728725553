import {Component} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {RuntimeTarget} from '../../../runtime-target';
import {AppStateService} from '../../state/app-state.service';
import {Observable} from 'rxjs';
import {Session} from '../../auth/session/session';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {
  collapsed = true;
  readonly currentSession$: Observable<Session>;

  constructor(private router: Router,
              public runtimeTarget: RuntimeTarget,
              public authService: AuthService,
              appState: AppStateService,
  ) {
    this.currentSession$ = appState.currentSession$;
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
