<div class="pb-3 border-bottom">
  <div class="d-flex flex-row justify-content-start align-items-center">
    @if (showBack) {
      <div class="me-2">
        <button class="btn btn-link" type="button" (click)="goBack()">
          <fa-icon [icon]="faChevronLeft" aria-hidden="true"></fa-icon>
          Back
        </button>
      </div>
    }

    @if (title) {
      <h1 class="me-2" role="heading" aria-level="1">{{title}}</h1>
    }

    <div class="ms-auto">
      @if (showEdit && editLink) {
        <button type="button"
                class="btn btn-link ms-2"
                aria-label="Edit item"
                routerLink="{{editLink}}">
          <fa-icon [icon]="faPencilAlt" aria-hidden="true"></fa-icon>
          Edit
        </button>
      }
    </div>
  </div>
</div>
