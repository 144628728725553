export enum ButtonSize {
  SMALL = 'sm',
  LARGE = 'lg'
}

export enum ButtonStyle {
  LINK = 'link',
  OUTLINE = 'outline',
}

export enum ButtonType {
  BUTTON = 'button',
  RESET = 'reset',
  SUBMIT = 'submit',
}
