import {Injectable} from '@angular/core';
import {UiAlert} from './ui-alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alerts: UiAlert[] = [];

  show(uiAlert: UiAlert): void {
    this.alerts.push(uiAlert);
  }

  remove(uiAlert: UiAlert): void {
    this.alerts = this.alerts.filter(a => a !== uiAlert);
  }
}
