<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
  <div class="container-fluid">
    <a class="navbar-brand ps-2" href="#">
      <img src="{{runtimeTarget.theme.logoUrl}}"
           alt="{{runtimeTarget.appName}}"
           role="img"
           height="40"/>
    </a>

    <button class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
            [attr.aria-expanded]="!collapsed"
            (click)="collapsed = !collapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    @if (currentSession$ | async; as session) {
      <div class="collapse navbar-collapse"
           id="navbarSupportedContent"
           [ngbCollapse]="collapsed">
        <!-- Seasons -->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <app-season-picker></app-season-picker>
        </ul>
        <!-- User -->
        <ul class="navbar-nav mt-2 mt-lg-0">
          <li ngbDropdown class="nav-item dropdown">
            <a ngbDropdownToggle
               class="nav-link dropdown-toggle"
               id="navbar-dropdown-user"
               data-bs-toggle="dropdown"
               role="button"
               aria-haspopup="true"
               aria-expanded="false">
              {{session.user.firstName}} {{session.user.lastName}}
            </a>
            <ul ngbDropdownMenu
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbar-dropdown-user">
              <li>
                <span class="dropdown-item-text fst-italic">Role: {{session.user.roleCode | userRoleCodeLabel}}</span>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <button ngbDropdownItem
                        class="dropdown-item"
                        type="button"
                        (click)="logout()">
                  Log Out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    }
  </div>
</nav>
