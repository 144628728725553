import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RuntimeConfig} from '../../../runtime-config';
import {AlertService} from '../../shared/ui-alert/alert.service';
import {Observable} from 'rxjs';
import {GameEditGrant} from '../model/game-edit-grant';
import {EntityService} from '../../shared/entity/entity.service';
import {ApiResponse} from '../../shared/entity/model/api-response';
import {sortBy} from 'lodash-es';

export interface GameEditGrantResponse extends ApiResponse {
  gameEditGrant: GameEditGrant;
}

export interface GameEditGrantsResponse extends ApiResponse {
  gameEditGrants: GameEditGrant[];
}

@Injectable({
  providedIn: 'root'
})
export class GameEditGrantService extends EntityService<GameEditGrant, GameEditGrantResponse, GameEditGrantsResponse> {

  constructor(
    httpClient: HttpClient,
    runtimeConfig: RuntimeConfig,
    alertService: AlertService,
  ) {
    super(httpClient, runtimeConfig, alertService, {
      apiPath: '/api/game-edit-grants',
      collectionRoute: '/game-edit-grants',
      idAttr: 'gameEditGrantId',
      allowCreate: false,
      allowDelete: false,
      parseItemResponse: response => response.gameEditGrant,
      parseListResponse: response => response.gameEditGrants,
      sortList: (items: GameEditGrant[]) => sortBy(items, item => item.gameEditGrantId),
      entityToString: item => `${item.gameId}`,
      singular: 'Game Edit Grant',
      plural: 'Game Edit Grants',
    });
  }

  findByToken(token: string): Observable<GameEditGrant> {
    return this.httpClient.get<GameEditGrantResponse>(
      `${this.baseUrl}/${token}`)
      .pipe(this.itemPipe);
  }
}
