@if (uiAlert) {
  <ngb-alert
    [dismissible]="uiAlert.dismissible ?? false"
    (closed)="onClosed()"
    [type]="uiAlert.type">
    @if (icon) {
      <fa-icon [icon]="icon" aria-hidden="true"></fa-icon>
    }
    {{uiAlert.text}}
    <ng-content></ng-content>
  </ngb-alert>
}
