import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {transformError} from '../utils/error-utils';
import {RuntimeConfig} from '../../runtime-config';
import {PitchCount} from './model/pitch-count';
import {PlayerStatus} from '../users/model/player-status';
import {EntityService} from '../shared/entity/entity.service';
import {AlertService} from '../shared/ui-alert/alert.service';
import {capitalize, sortBy, upperCase} from 'lodash-es';
import {ApiResponse} from '../shared/entity/model/api-response';
import {PitchCountTotal} from './model/pitch-count-total';
import {Team} from '../teams/model/team';
import {User} from '../users/model/user';
import {Game} from '../games/model/game';
import {PlayerPosition} from './model/player-position';
import {PitchCountAgeGroupReport} from './model/pitch-count-age-group-report';
import {PitchCountPlayerReport} from './model/pitch-count-player-report';
import {DateUtils} from '../utils/date-utils';

export interface PitchCountResponse extends ApiResponse {
  pitchCount: PitchCount;
}

export interface PitchCountsResponse extends ApiResponse {
  pitchCounts: PitchCount[];
}

export interface PlayerStatusesResponse extends ApiResponse {
  roster: PlayerStatus[];
}

interface GamePitchCountsResponse extends ApiResponse {
  game: Game;
  awayPitchCounts?: PitchCount[];
  awayRoster?: User[];
  homePitchCounts?: PitchCount[];
  homeRoster?: User[];
}


@Injectable({
  providedIn: 'root'
})
export class PitchCountService extends EntityService<PitchCount, PitchCountResponse, PitchCountsResponse> {

  constructor(
    httpClient: HttpClient,
    runtimeConfig: RuntimeConfig,
    alertService: AlertService,
  ) {
    super(httpClient, runtimeConfig, alertService, {
      apiPath: '/api/pitchCounts',
      collectionRoute: '',
      idAttr: 'pitchCountId',
      allowCreate: false,
      allowDelete: false,
      parseItemResponse: response => response.pitchCount,
      parseListResponse: response => response.pitchCounts,
      sortList: (items: PitchCount[]) => items, // no sorting for now
      entityToString: item => item.pitchCountId != null ? item.pitchCountId.toString() : '',
      singular: 'Pitch Count',
      plural: 'Pitch Counts',
    });
  }

  findPlayerReport(userId: number): Observable<PitchCountPlayerReport> {
    const params = new HttpParams().set('from', DateUtils.getCurrentDateISOString());

    return this.httpClient.get<PitchCountPlayerReport>(`${this.baseUrl}/users/${userId}/report`, {params})
      .pipe(
        map(response => {
          response.appearances = response.appearances.filter(appearance => appearance.gameId != null);
          return response;
        }),
        catchError(transformError),
      );
  }

  findPlayerStatusesByTeamId(teamId: number): Observable<PlayerStatus[]> {
    return this.httpClient.get<PlayerStatusesResponse>(`${this.baseUrl}/teams/${teamId}`)
      .pipe(
        map(response => response.roster),
        map((roster: PlayerStatus[]) => sortBy(roster, [r => upperCase(r.lastName), r => upperCase(r.firstName)])),
        catchError(transformError),
      );
  }

  findPitchCountTotalsByGameId(gameId: number): Observable<PitchCountTotal[]> {
    const params = new HttpParams().set('position', 'ALL');

    return this.httpClient.get<GamePitchCountsResponse>(`${this.baseUrl}/games/${gameId}`, {params})
      .pipe(
        map(response => this.gamePitchCountsResponseToPitchCountTotals(response)),
        map((pitchCountTotals: PitchCountTotal[]) =>
          sortBy(pitchCountTotals, [r => upperCase(r.user?.lastName ?? ''), r => upperCase(r.user?.firstName ?? '')]),
        ),
        catchError(transformError),
      );
  }

  private gamePitchCountsResponseToPitchCountTotals(response: GamePitchCountsResponse): PitchCountTotal[] {
    let result: PitchCountTotal[] = [];
    if (response.game.homeTeam && response.homeRoster) {
      result = result.concat(this.createPitchCountTotals(response.game.homeTeam, response.homeRoster, response.homePitchCounts));
    }
    if (response.game.awayTeam && response.awayRoster) {
      result = result.concat(this.createPitchCountTotals(response.game.awayTeam, response.awayRoster, response.awayPitchCounts));
    }

    return result;
  }

  private createPitchCountTotals(team: Team, players: User[], pitchCounts: PitchCount[] = []): PitchCountTotal[] {
    if (!team || !players) {
      return [];
    }

    const pctByUserMap = new Map<number, PitchCountTotal>();
    pitchCounts.forEach(pc => {
      const key = pc.userId;
      if (!pctByUserMap.has(key)) {
        pctByUserMap.set(key, {
          userId: pc.userId,
          user: this.getUserByUserId(pc.userId, players),
          team,
          pitchCount: 0,
          playerPositions: [],
        });
      }

      const pct = pctByUserMap.get(key);
      if (pct != null) {
        if (pc.playerPosition === PlayerPosition.PITCHER) {
          pct.pitchCount += pc.pitchCount ?? 0;
        }

        if (pct.playerPositions.indexOf(pc.playerPosition) === -1) {
          pct.playerPositions.push(pc.playerPosition);
          pct.playerPositions.sort();
        }
      }
    });

    return [...pctByUserMap.values()];
  }

  private getUserByUserId(userId: number, users: User[]): User | undefined {
    return users.find(user => user.userId === userId);
  }

  fetchExport(): Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.baseUrl}/export`, {responseType: 'blob' as 'json'});
  }

  fetchAgeGroupReport(): Observable<PitchCountAgeGroupReport> {
    return this.httpClient.get<PitchCountAgeGroupReport>(`${this.baseUrl}/age-groups/report`)
      .pipe(
        catchError(transformError)
      );
  }

  requestBulkRollups(userId: number): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(
      `${this.baseUrl}/bulkRollup`, {userId})
      .pipe(
        catchError(transformError),
      );
  }

  static getPositionsDisplay(playerPositions?: PlayerPosition[]): string {
    if (playerPositions) {
      return playerPositions.map(pos => capitalize(pos)).join('; ');
    }
    return '';
  }
}
