<div class="d-flex align-items-center"
     [ngClass]="{'mb-4': hasData()}">

  @if (hasData()) {
    @if (allowSearch) {
      <div class="me-2">
        <app-search-input [id]="nameSingular + 'SearchInput'"
                          [entityNamePlural]="namePlural"
                          (valueChanged)="onSearchValueChanged($event)"></app-search-input>
      </div>
    }

    @if (collectionFilter) {
      <div class="me-2">
        <label [for]="collectionFilter.id"
               class="form-label visually-hidden">
          Filter {{namePlural}}
        </label>
        <select [(ngModel)]="collectionFilter.selectedOption"
                (ngModelChange)="onSelectedFilterChange()"
                [id]="collectionFilter.id"
                class="form-select"
                [attr.aria-label]="'Filter ' + namePlural">
          @for (option of collectionFilter.options; track option.id) {
            <option [ngValue]="option">{{option.label}}</option>
          }
        </select>
      </div>
    }
  }

  <div class="flex-grow-1 text-end">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>

<app-loading-indicator [loading]="loading"></app-loading-indicator>

<app-data-table [rows]="filteredRows"
                [caption]="namePlural"
                [pageSize]="pageSize"
                [small]="smallTable"
                [pageAndSort]="pageAndSort"
                (pageAndSortChanged)="onPageAndSortChanged($event)"></app-data-table>

@if (!loading) {
  @if (!hasData()) {
    <app-empty-state [text]="emptyText"></app-empty-state>
  } @else {
    @if (!hasFilteredData()) {
      <app-empty-state [text]="filterEmptyText"></app-empty-state>
    }
  }
}

