import {Injectable} from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {RuntimeTarget} from '../runtime-target';

@Injectable({
  providedIn: 'root'
})
export class AppTitleStrategy extends TitleStrategy {

  titleCore = '';
  readonly delimiter = '|';

  constructor(private readonly title: Title,
              private readonly runtimeTarget: RuntimeTarget
  ) {
    super();
    this.titleCore = runtimeTarget.consoleName;
  }


  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);

    if (title != null) {
      this.title.setTitle(`${title} ${this.delimiter} ${this.titleCore}`);
    } else {
      this.title.setTitle(this.titleCore);
    }
  }
}
