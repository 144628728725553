import {Component, Input} from '@angular/core';
import {faChevronLeft, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {Location} from '@angular/common';

@Component({
  selector: 'app-sub-navbar',
  templateUrl: './sub-navbar.component.html'
})
export class SubNavbarComponent {
  @Input() title?: string;
  @Input() showBack = true;
  @Input() showEdit = false;
  @Input() editLink?: string;

  readonly faChevronLeft = faChevronLeft;
  readonly faPencilAlt = faPencilAlt;

  constructor(private location: Location) {
  }

  goBack(): void {
    this.location.back();
  }
}
