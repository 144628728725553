export enum ValidationErrorKey {
  EMAIL = 'email',
  INVALID = 'invalid',
  INVALID_DATE_RANGE = 'invalidDateRange',
  MAX_LENGTH = 'maxlength',
  MIN_LENGTH = 'minlength',
  MAX = 'max',
  MIN = 'min',
  MUST_MATCH = 'mustMatch',
  MUST_NOT_MATCH = 'mustNotMatch',
  REQUIRED = 'required',
  AT_LEAST_ONE_REQUIRED = 'atLeastOneRequired',
}
