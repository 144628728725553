<label [for]="id" class="form-label visually-hidden">
  Search {{entityNamePlural}}
</label>
<input #searchInput
       (input)="onSearchEntered(searchInput.value)"
       [id]="id"
       type="search"
       class="form-control"
       [attr.aria-label]="'Search ' + entityNamePlural"
       [placeholder]="'Search ' + (entityNamePlural | lowercase)  + '...'"/>
