<main id="main-content" class="d-flex justify-content-center">
  <div class="mx-2 my-5">
    <img class="mb-4 mx-auto d-block"
         src="{{runtimeTarget.theme.logoUrl}}"
         alt="{{runtimeTarget.appName}}"
         height="60"/>
    <h1 class="text-center text-primary mb-4"
        role="heading"
        aria-level="1">
      Pitch Count Entry
    </h1>

    <div aria-live="polite" aria-atomic="true">
      <app-alert [uiAlert]="alert"></app-alert>
    </div>

    <app-loading-indicator [loading]="loading"></app-loading-indicator>

    @if (gameEditGrant) {
      <div>
        <div class="h4 text-center">
          {{gameEditGrant.grantorUser.firstName}} {{gameEditGrant.grantorUser.lastName}}
        </div>
        <div class="text-center mb-4">
          has granted you access to enter pitch counts for this game:
        </div>
        <div class="card mx-2 mb-5">
          <div class="card-body">
            <div class="card-text h5">
              @if (gameEditGrant.game.awayTeam) {
                {{gameEditGrant.game.awayTeam.name}}
                @if (gameEditGrant.game.homeTeam) {
                  &#64;
                }
              }
              @if (gameEditGrant.game.homeTeam) {
                {{gameEditGrant.game.homeTeam.name}}
              }
            </div>

            <div class="card-text">
              {{gameEditGrant.game.gameDate | dateUtc:'longDate'}}
              @if (gameEditGrant.game.gameTime) {
                <span class="ms-1">
                  {{gameEditGrant.game.gameTime | time:'shortTime'}}
                </span>
              }
            </div>
            <div class="card-text">
              {{gameEditGrant.game.location}}
            </div>
          </div>
        </div>

        <div class="text-center mb-4">
          You will need to install the Pitch Count app if you have not already.
          <div class="d-flex justify-content-evenly align-items-center">
            <div>
              <a href="{{runtimeTarget.redirects.iosUrl}}"
                 target="_blank"><img src="/assets/images/app-store-badge.svg"
                                      role="img"
                                      height="40"
                                      alt="Get Pitch Count on the App Store"></a>
            </div>
            <div>
              <a href="{{runtimeTarget.redirects.androidUrl}}"
                 target="_blank"><img src="/assets/images/google-play-badge.png"
                                      height="60"
                                      alt="Get Pitch Count on Google Play"></a>
            </div>
          </div>
        </div>
        <div>
          Once your app is installed, click "Continue."
        </div>
        <div class="mt-4 text-center">
          <a class="btn btn-primary w-100"
             href="{{baseUrl}}/app-redirect?path={{'game-edit-grants%2F' + gameEditGrant.token}}"
             target="_blank">Continue</a>
        </div>
      </div>
    }

  </div>
</main>
