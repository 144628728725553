@if (selectedSeason) {
  <div>
    <div class="mb-5">
      <h1 role="heading" aria-level="1">
        {{selectedSeason.name}}
        @if (selectedSeason.isCurrent) {
          <app-current-season-indicator></app-current-season-indicator>
        }
        @if (currentSession$ | async; as currentSession) {
          @if (currentSession.isAdmin()) {
            <button type="button"
                    class="btn btn-link ms-3"
                    routerLink="/seasons/{{selectedSeason.seasonId}}/edit">
              <fa-icon [icon]="faPencilAlt" aria-hidden="true"></fa-icon>
              Edit
            </button>
          }
        }
      </h1>

      @if (selectedSeason.startDate || selectedSeason.endDate) {
        <div>
          {{selectedSeason.startDate | dateUtc:'longDate'}}
          &#8211;
          {{selectedSeason.endDate | dateUtc:'longDate'}}
        </div>
      }
    </div>

    <app-loading-indicator [loading]="loading"></app-loading-indicator>

    @if (!loading) {
      <div class="row row-cols-1 row-cols-lg-2 g-4">
        @if (pitchCountAgeGroupReport) {
          <div class="col">
            <app-pitch-count-report [report]="pitchCountAgeGroupReport"></app-pitch-count-report>
          </div>
        }
      </div>
    }
  </div>
}
