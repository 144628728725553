import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CacheService} from './cache.service';
import {catchError, distinctUntilChanged, filter, map, skip, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {transformError} from '../utils/error-utils';
import {Credentials} from './credentials';
import {AuthStatus, defaultAuthStatus} from './auth-status';
import {RuntimeConfig} from '../../runtime-config';
import {RuntimeTarget} from '../../runtime-target';
import {Router} from '@angular/router';
import {AppStateService} from '../state/app-state.service';
import {User} from '../users/model/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CacheService {
  private static readonly CACHE_KEY_AUTH_TOKEN = 'authToken';
  private static readonly CACHE_KEY_APP_USER_ID = 'userId';

  readonly authStatus$ = new BehaviorSubject<AuthStatus>(defaultAuthStatus);

  constructor(private httpClient: HttpClient,
              private router: Router,
              private runtimeConfig: RuntimeConfig,
              private runtimeTarget: RuntimeTarget,
              private appState: AppStateService,
  ) {
    super();
    this.resume();
    this.initSessionChangeListener();
  }

  initSessionChangeListener(): void {
    this.appState.currentSession$.pipe(
      filter(session => session.isValid()),
      distinctUntilChanged(),
      skip(1),
    ).subscribe((session) => this.router.navigate(['']));
  }

  private resume(): void {
    this.authStatus$.next(this.retrieveAuthStatusFromCache());

    // Wait for all services to be ready to avoid dependency cycle error
    // since we are invoking the injected service in the constructor
    setTimeout(() => {
      if (this.authStatus$.value.isAuthenticated) {
        this.appState.findCurrentSession();
      }
    });
  }

  login(credentials: Credentials): Observable<void> {
    this.clearTokenAndUserId();

    return this.doAuth(credentials)
      .pipe(
        // Cache and publish auth status update
        tap((status) => {
          this.updateCache(status);
          this.authStatus$.next(status);
        }),
        // Check auth success and publish new session
        filter((status: AuthStatus) => status.isAuthenticated),
        map(() => this.appState.findCurrentSession()),
        // Log out if we hit a pothole
        catchError(err => {
          this.logout();
          return transformError(err);
        })
      );
  }

  private doAuth(credentials: Credentials): Observable<AuthStatus> {
    interface AuthResponse {
      success: boolean;
      error?: string;
      token?: string;
      user?: User;
    }

    return this.httpClient.post<AuthResponse>(
      `${this.runtimeConfig.baseUrl}/api/auth`,
      {
        email: credentials.email,
        password: credentials.password,
        appKey: this.runtimeTarget.appKey
      }).pipe(
      map(value => {
        if (value.error) {
          throw new Error(value.error);
        }
        return {
          isAuthenticated: value.success,
          token: value.token,
          userId: value.user?.userId
        };
      })
    );
  }

  logout(): void {
    this.clearTokenAndUserId();
    this.authStatus$.next(defaultAuthStatus);
  }

  private updateCache(value: AuthStatus): void {
    if (value.token) {
      this.saveToken(value.token);
    }
    if (value.userId) {
      this.saveUserId(value.userId);
    }
  }

  private retrieveAuthStatusFromCache(): AuthStatus {
    return {
      isAuthenticated: this.getToken().length > 0,
      userId: this.getUserId()
    };
  }

  private saveToken(token: string): void {
    this.setItem(AuthService.CACHE_KEY_AUTH_TOKEN, token);
  }

  private saveUserId(userId: number): void {
    this.setItem(AuthService.CACHE_KEY_APP_USER_ID, '' + userId);
  }

  private clearTokenAndUserId(): void {
    this.removeItem(AuthService.CACHE_KEY_AUTH_TOKEN);
    this.removeItem(AuthService.CACHE_KEY_APP_USER_ID);
  }

  getToken(): string {
    return this.getItem(AuthService.CACHE_KEY_AUTH_TOKEN) ?? '';
  }

  getUserId(): number | undefined {
    const idString = this.getItem(AuthService.CACHE_KEY_APP_USER_ID) as string;
    return idString ? +idString : undefined;
  }
}
