import {Component, Input} from '@angular/core';
import {BootstrapColorVariant} from '../bootstrap/types';

@Component({
  selector: 'app-empty-state',
  template: `
    <div class="h5 my-4 text-muted" [ngClass]="getClasses()">{{text}}</div>
  `,
})
export class EmptyStateComponent {
  @Input() text = 'No data found.';
  @Input() classes: string[] = [];
  @Input() color?: BootstrapColorVariant;

  getClasses(): string[] {
    const result: string[] = [...this.classes];
    if (this.color) {
      result.push(`text-${this.color}`);
    }

    return result;
  }
}
