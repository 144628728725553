export interface PageAndSort {
  page: number;
  sort?: string;
  sortDir?: SortDir;
}

export enum SortDir {
  ASC = 'ASC',
  DESC = 'DESC',
}
