@if (form) {
  <label [for]="id"
         [ngClass]="{'form-label-required': required}"
         class="form-label">{{label}}</label>
  <div class="input-group" [formGroup]="form">
    <input [id]="id"
           maxlength="10"
           type="text"
           class="form-control"
           autocomplete="off"
           [formControlName]="controlName"
           ngbDatepicker #d="ngbDatepicker"
           [placeholder]="placeholder"
           [fieldLabel]="label"
           [linkedControls]="linkedControls"
           [messages]="fieldErrorMessages"
           [submitted]="submitted"
           appFieldError>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="d.toggle()">
      <fa-icon [icon]="faCalendarAlt"
               aria-hidden="true"></fa-icon>
      <span class="visually-hidden">{{label}}</span>
    </button>
  </div>
}
