import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UiAlert} from '../../shared/ui-alert/ui-alert';
import {RuntimeTarget} from '../../../runtime-target';
import {GameEditGrantService} from '../service/game-edit-grant.service';
import {GameEditGrant} from '../model/game-edit-grant';
import {catchError, filter, finalize, takeUntil, tap} from 'rxjs/operators';
import {BootstrapColorVariant} from '../../shared/bootstrap/types';
import {of, Subject} from 'rxjs';

@Component({
  selector: 'app-game-edit-grant-help',
  templateUrl: './game-edit-grant-help.component.html',
})
export class GameEditGrantHelpComponent implements OnInit, OnDestroy {
  token = '';
  gameEditGrant?: GameEditGrant;
  baseUrl: string;

  alert?: UiAlert;
  loading = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    public runtimeTarget: RuntimeTarget,
    private gameEditGrantService: GameEditGrantService,
  ) {
    this.baseUrl = window.location.origin;
  }

  ngOnInit(): void {
    this.setTokenFromRequest();
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private setTokenFromRequest() {
    this.token = this.route.snapshot.paramMap.get('token') ?? '';
  }

  private fetchData() {
    this.loading = true;
    this.gameEditGrantService.findByToken(this.token)
      .pipe(
        filter(val => val != null),
        tap((gameEditGrant: GameEditGrant) => {
          this.gameEditGrant = gameEditGrant;
        }),
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.loading = false),
        catchError(error => {
          this.alert = {
            text: 'This token is invalid.',
            type: BootstrapColorVariant.DANGER,
          };
          return of({});
        }),
      )
      .subscribe();
  }
}
