<footer>
  <div class="container">
    <p class="text-center text-muted py-5">
      <small>Copyright &#169; {{year}}
        <a class="text-muted"
           href="https://www.newstartmobile.com"
           target="_blank">New Start Mobile, LLC</a></small>
    </p>
  </div>
</footer>
