import {Component, OnInit} from '@angular/core';
import {FormControlName, PasswordResetComponent} from '../password-reset.component';
import {catchError, filter, finalize, tap} from 'rxjs/operators';
import {ApiResponse} from '../../../shared/entity/model/api-response';
import {BootstrapColorVariant} from '../../../shared/bootstrap/types';
import {of} from 'rxjs';
import {AcceptInvitationRequest} from '../../../users/model/accept-invitation-request';

@Component({
  selector: 'app-invitation-accept',
  templateUrl: '../password-reset.component.html',
})
export class InvitationAcceptComponent extends PasswordResetComponent implements OnInit {

  override initText() {
    this.title = 'Accept Invitation';
    this.passwordLabel = 'New Password';
    this.passwordConfirmLabel = 'Confirm Password';
    this.buttonLabel = 'Accept Invitation';
  }

  override submit() {
    this.alert = undefined;
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    this.userService.acceptInvitation(this.token, this.getAcceptInvitationRequest())
      .pipe(
        filter(val => val != null),
        tap((response: ApiResponse) => {
          if (response.success) {
            this.succeeded = true;
            this.alert = {
              text: `Your password has been set.`,
              type: BootstrapColorVariant.SUCCESS,
            };
          } else {
            this.alert = {
              text: `Your password could not be set: ${response.error}`,
              type: BootstrapColorVariant.DANGER,
            };
          }
        }),
        finalize(() => {
          this.loading = false;
          this.submitted = false;
        }),
        catchError(error => {
          this.alert = {
            text: `Your password could not be set: ${error}`,
            type: BootstrapColorVariant.DANGER,
          };
          return of({});
        }),
      )
      .subscribe();
  }

  private getAcceptInvitationRequest(): AcceptInvitationRequest {
    return {
      newPassword: this.form.get(FormControlName.PASSWORD)?.value ?? '',
    };
  }
}
