import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './auth/login/login.component';
import {PasswordResetComponent} from './auth/password-reset/password-reset.component';
import {AppLayoutComponent} from './layout/app-layout/app-layout.component';
import {MobileAppRedirectComponent} from './mobile-app-redirect/mobile-app-redirect.component';
import {InvitationAcceptComponent} from './auth/password-reset/invitation-accept/invitation-accept.component';
import {GameEditGrantHelpComponent} from './game-edit-grants/game-edit-grant-help/game-edit-grant-help.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  // App-layout routes
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent,
        title: 'Dashboard',
      },
      {
        path: 'affiliates',
        loadChildren: () => import('./affiliates/affiliates.module').then(m => m.AffiliatesModule),
      },
      {
        path: 'clubs',
        loadChildren: () => import('./clubs/clubs.module').then(m => m.ClubsModule),
      },
      {
        path: 'divisions',
        loadChildren: () => import('./divisions/divisions.module').then(m => m.DivisionsModule),
      },
      {
        path: 'imports',
        loadChildren: () => import('./import-jobs/import-jobs.module').then(m => m.ImportJobsModule),
      },
      {
        path: 'games',
        loadChildren: () => import('./games/games.module').then(m => m.GamesModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      },
      {
        path: 'seasons',
        loadChildren: () => import('./seasons/seasons.module').then(m => m.SeasonsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./app-configs/app-configs.module').then(m => m.AppConfigsModule),
      },
      {
        path: 'teams',
        loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
      },
    ]
  },
  // No-layout routes
  {path: 'login', component: LoginComponent, title: 'Log In'},
  {path: 'login/:redirectUrl', component: LoginComponent, title: 'Log In'},
  {path: 'accept-invitation/:token', component: InvitationAcceptComponent, title: 'Accept Invitation'},
  {path: 'reset-password/:token', component: PasswordResetComponent, title: 'Reset Password'},
  {path: 'game-edit-grants/:token', component: GameEditGrantHelpComponent, title: 'Pitch Count Entry'},
  {path: 'app-redirect', component: MobileAppRedirectComponent},
  // Default
  {path: '**', redirectTo: '/home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
