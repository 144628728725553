import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateUtc'
})
export class DateUtcPipe implements PipeTransform {
  readonly tzUtc = '+0000';

  constructor(public datePipe: DatePipe) {
  }

  transform(value: Date | string | number | null | undefined,
            format?: string,
            locale?: string): string | null {
    return this.datePipe.transform(value, format, this.tzUtc, locale);
  }
}
