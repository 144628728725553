@if (report) {
  <div class="card">
    <h2 class="card-header h5">{{reportTitle}}</h2>
    <div class="card-body">
      @if (rows) {
        <app-data-table [rows]="rows"
                        [small]="true"
                        [caption]="'Pitch count metrics by age group'"
                        [pageAndSort]="pageAndSort"></app-data-table>
      }
    </div>
  </div>
}
