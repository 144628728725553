import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {TeamRole} from '../../users/model/team-role';
import {AppStateService} from '../../state/app-state.service';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {BootstrapColorVariant} from '../bootstrap/types';
import {ButtonStyle} from '../form/button/types';

@Component({
  selector: 'app-team-roles-dropdown',
  templateUrl: './team-roles-dropdown.component.html',
})
export class TeamRolesDropdownComponent {
  @Input() controlId = '';
  @Input() label = '';
  @Input() iconDef?: IconDefinition;
  @Input() buttonColor: BootstrapColorVariant = BootstrapColorVariant.SUCCESS;
  @Input() buttonStyle: ButtonStyle = ButtonStyle.OUTLINE;

  @Output() clicked = new EventEmitter<TeamRole>();

  teamRoles$: Observable<TeamRole[]>;

  constructor(private appState: AppStateService,) {
    this.teamRoles$ = appState.teamRoles$;
  }

  getClasses(): string[] {
    const result: string[] = [];

    if (this.buttonStyle === ButtonStyle.OUTLINE) {
      result.push('btn-outline-' + this.buttonColor);
    } else if (this.buttonStyle === ButtonStyle.LINK) {
      result.push('btn-link');
      result.push('text-' + this.buttonColor);
    } else {
      result.push('btn-' + this.buttonColor);
    }

    return result;
  }

  onClick(teamRole: TeamRole) {
    this.clicked.emit(teamRole);
  }
}
