import {Component, OnInit} from '@angular/core';
import {RedirectUrls, RuntimeTarget} from '../../runtime-target';
import {BrowserUtils, MobileOS} from '../utils/browser-utils';


@Component({
  selector: 'app-mobile-app-redirect',
  templateUrl: './mobile-app-redirect.component.html',
})
export class MobileAppRedirectComponent implements OnInit {

  constructor(private runtimeTarget: RuntimeTarget) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.redirect(this.runtimeTarget.redirects);
    }, 2000);
  }

  redirect(redirectUrls: RedirectUrls) {
    let location;
    switch (BrowserUtils.getMobileOS()) {
      case MobileOS.IOS:
        location = redirectUrls.iosUrl;
        break;
      case MobileOS.ANDROID:
        location = redirectUrls.androidUrl;
        break;
      default: {
        location = redirectUrls.defaultUrl;
        const urlParams = new URLSearchParams(window.location.search);
        const incomingPath = urlParams.get('path');
        if (incomingPath) {
          location += '/' + incomingPath;
        }
      }
    }

    if (location != null) {
      window.location.href = location;
    } else {
      console.warn('No location');
    }
  }
}
