export type MaybeNumber = string | null | undefined;

export class NumberUtils {

  static isNumeric(val?: unknown): boolean {
    if (val == null) {
      return false;
    }
    if (Number.isFinite(val)) {
      return true;
    }
    if (typeof val != 'string') {
      return false;
    }

    return !isNaN(Number(val)) && !isNaN(parseFloat(val));
  }
}
