import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../model/user';
import {RuntimeConfig} from '../../../runtime-config';
import {EntityService} from '../../shared/entity/entity.service';
import {AlertService} from '../../shared/ui-alert/alert.service';
import {sortBy} from 'lodash-es';
import {Observable} from 'rxjs';
import {InviteStatus} from '../model/invite-status';
import {catchError} from 'rxjs/operators';
import {transformError} from '../../utils/error-utils';
import {ResetPasswordRequest} from '../model/reset-password-request';
import {ApiResponse} from '../../shared/entity/model/api-response';
import {AcceptInvitationRequest} from '../model/accept-invitation-request';
import {TeamRoleName} from '../model/team-role';

export interface UserResponse extends ApiResponse {
  user: User;
}

export interface UsersResponse extends ApiResponse {
  users: User[];
}

/*
 * Service for non-admin Users.
 * See also: AdminService
 */
@Injectable({
  providedIn: 'root'
})
export class UserService extends EntityService<User, UserResponse, UsersResponse> {

  constructor(
    httpClient: HttpClient,
    runtimeConfig: RuntimeConfig,
    alertService: AlertService,
  ) {
    super(httpClient, runtimeConfig, alertService, {
      apiPath: '/api/users',
      collectionRoute: '/users',
      idAttr: 'userId',
      allowCreate: true,
      allowDelete: true,
      parseItemResponse: response => response.user,
      parseListResponse: response => response.users,
      sortList: (items: User[]) => sortBy(items, item => item.userId),
      entityToString: item => `${item.firstName} ${item.lastName}`,
      singular: 'Person',
      plural: 'People',
    });
  }

  override findAll(): Observable<User[]> {
    return this.httpClient.get<UsersResponse>(`${this.baseUrl}/nonguests`)
      .pipe(this.listPipe);
  }

  findByToken(token: string): Observable<User> {
    return this.httpClient.get<UserResponse>(
      `${this.baseUrl}/token/${token}`)
      .pipe(this.itemPipe);
  }

  resetPassword(token: string, resetPasswordRequest: ResetPasswordRequest): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${this.baseUrl}/${token}/forgotPassword`, resetPasswordRequest)
      .pipe(
        catchError(transformError),
      );
  }

  sendResetPasswordRequest(userId: number): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(
      `${this.baseUrl}/forgotPasswordRequest`, {userId})
      .pipe(
        catchError(transformError),
      );
  }

  sendInvitation(userId: number): Observable<UserResponse> {
    return this.httpClient.get<UserResponse>(
      `${this.baseUrl}/${userId}/sendInvitation`)
      .pipe(
        catchError(transformError),
      );
  }

  sendInvitationsByTeamRole(teamRoleName: TeamRoleName): Observable<ApiResponse> {
    return this.httpClient.get<UserResponse>(
      `${this.baseUrl}/roles/${teamRoleName}/sendInvitations`)
      .pipe(
        catchError(transformError),
      );
  }

  acceptInvitation(token: string, acceptInvitationRequest: AcceptInvitationRequest): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${this.baseUrl}/${token}/acceptInvitation`, acceptInvitationRequest)
      .pipe(
        catchError(transformError),
      );
  }

  static getInviteStatus(user: User): InviteStatus {
    if (user.email == null) {
      return InviteStatus.NONE;
    }

    if (user.timeInvitationSent == null) {
      if (user.timeInvitationRequested != null) {
        return InviteStatus.REQUESTED;
      }
      return InviteStatus.NOT_SENT;
    }
    if (user.timeInvitationAccepted == null) {
      return InviteStatus.SENT;
    } else {
      return InviteStatus.ACCEPTED;
    }
  }

  static getInviteStatusUpdated(user: User): Date | undefined {
    if (user.email == null) {
      return;
    }

    if (user.timeInvitationSent == null) {
      if (user.timeInvitationRequested != null) {
        return new Date(user.timeInvitationRequested);
      }
      return;
    }
    if (user.timeInvitationAccepted == null) {
      return new Date(user.timeInvitationSent);
    } else {
      return new Date(user.timeInvitationAccepted);
    }
  }
}
