@if (seasons$ | async; as seasons) {
  @if (selectedSeason$ | async; as selectedSeason) {
    @if (currentSession$ | async; as currentSession) {
      <li ngbDropdown class="nav-item dropdown">
        <a ngbDropdownToggle
           class="nav-link dropdown-toggle"
           id="navbar-dropdown-seasons"
           data-bs-toggle="dropdown"
           role="button"
           aria-haspopup="true"
           aria-expanded="false">
          {{selectedSeason.name}}
          @if (selectedSeason.isCurrent) {
            <app-current-season-indicator></app-current-season-indicator>
          }
        </a>
        <ul ngbDropdownMenu
            class="dropdown-menu"
            aria-labelledby="navbar-dropdown-seasons">
          @for (season of seasons; track season.seasonId) {
            <li>
              <button ngbDropdownItem
                      type="button"
                      [ngClass]="{'active': season.seasonId === selectedSeason?.seasonId}"
                      [attr.aria-current]="season.seasonId === selectedSeason?.seasonId"
                      (click)="updateSelectedSeason(season.seasonId)">
                {{season.name}}
                @if (season.isCurrent) {
                  <app-current-season-indicator
                    [muted]="season.seasonId === selectedSeason?.seasonId"></app-current-season-indicator>
                }
                @if (season.seasonId === selectedSeason?.seasonId) {
                  <span class="small">&check;</span>
                }
              </button>
            </li>
          }

          @if (currentSession.isAdmin()) {
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <button ngbDropdownItem
                      type="button"
                      class="text-success"
                      routerLink="/seasons/create">
                <fa-icon [icon]="faPlus" aria-hidden="true"></fa-icon>
                Add Season
              </button>
            </li>
          }
        </ul>
      </li>
    }
  }
}
