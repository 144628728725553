export type MaybeString = string | null | undefined;

export class StringUtils {

  static stringIncludesIgnoreCase(value: MaybeString, includes: MaybeString): boolean {
    if (value == null && includes == null) {
      return true;
    }
    if (value != null && includes != null) {
      return value.toLowerCase().includes(includes.toLowerCase());
    }
    return false;
  }

  static isBlank(value: MaybeString): boolean {
    return value == null || value.trim() === '';
  }

  static isNotBlank(value: MaybeString): boolean {
    return !this.isBlank(value);
  }
}
