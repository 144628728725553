import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

/**
 *  Custom NgbDateParserFormatter for ng-bootstrap DatePickers using dayjs to parse/format dates.
 *  https://ng-bootstrap.github.io/#/components/datepicker/overview#date-model
 *
 *  Usage: place the following in the appropriate @Module:
 *    providers: [
 *      {
 *        provide: NgbDateParserFormatter,
 *        useFactory: () => new NgbCustomDateAdapter(ISO_8601_DATE_FORMAT)
 *      }
 *    ]
 */
export class NgbCustomDateParserFormatter extends NgbDateParserFormatter {

  constructor(private dateFormat: string) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }

    const d = dayjs(value, this.dateFormat);
    return d.isValid() ? {
      year: d.year(),
      month: d.month() + 1,
      day: d.date()
    } : null;
  }

  format(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }

    const d = dayjs().year(date.year).month(date.month - 1).date(date.day);
    return d.isValid() ? d.format(this.dateFormat) : '';
  }
}
