<main id="main-content" class="d-flex justify-content-center">
  <div class="my-5">
    <img class="mb-4 mx-auto d-block"
         src="{{runtimeTarget.theme.logoUrl}}"
         alt="{{runtimeTarget.appName}}"
         height="100px"/>
    <h1 class="text-center mb-4"
        role="heading"
        aria-level="1">
      {{title}}
    </h1>

    <div aria-live="polite" aria-atomic="true">
      <app-alert [uiAlert]="alert"></app-alert>
    </div>

    @if (form && !succeeded) {
      <div class="card mt-4">
        <form [formGroup]="form"
              (ngSubmit)="submit()"
              class="card-body"
              aria-label="Reset-password form">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input id="email"
                   inputmode="email"
                   autocomplete="username"
                   type="email"
                   class="form-control"
                   [formControlName]="FormControlName.EMAIL">
          </div>
          <div class="mb-3">
            <label for="passwordCtrl" class="form-label form-label-required">{{passwordLabel}}</label>
            <input id="passwordCtrl"
                   class="form-control"
                   type="password"
                   autocomplete="new-password"
                   [formControlName]="FormControlName.PASSWORD"
                   [fieldLabel]="passwordLabel"
                   [submitted]="submitted"
                   appFieldError>
          </div>
          <div class="mb-3">
            <label for="passwordConfirmCtrl" class="form-label form-label-required">{{passwordConfirmLabel}}</label>
            <input id="passwordConfirmCtrl"
                   class="form-control"
                   type="password"
                   autocomplete="new-password"
                   [formControlName]="FormControlName.PASSWORD_CONFIRM"
                   [fieldLabel]="passwordConfirmLabel"
                   [submitted]="submitted"
                   [messages]="[{errorKey: ValidationErrorKey.MUST_MATCH, message: 'Passwords must match.'}]"
                   appFieldError>
          </div>
          <app-button [buttonType]="ButtonType.SUBMIT"
                      [displayBlock]="true"
                      [label]="buttonLabel"
                      [loading]="loading"
                      [disabled]="loading"></app-button>
        </form>
      </div>
    }

    @if (succeeded) {
      <div class="mt-4 text-center">
        <button type="button"
                class="btn btn-outline-primary btn-block"
                routerLink="/app-redirect">
          Return to the App
        </button>
      </div>
    }
  </div>
</main>
