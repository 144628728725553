import {User} from '../../users/model/user';
import {UserRoleCode} from '../../users/model/user-role-code';

export interface SessionAttrs {
  token: string;
  userId: number;
  roleCode: UserRoleCode;
  seasonId: number;
  timeOfExpiration: number;
  user: User;
}

export class Session implements SessionAttrs {

  constructor(
    public token = '',
    public userId = 0,
    public roleCode: UserRoleCode = UserRoleCode.USER,
    public seasonId = 0,
    public timeOfExpiration = 0,
    public user: User = {} as User,
  ) {
  }

  isAdmin(): boolean {
    return this.roleCode === UserRoleCode.ADMIN;
  }

  isAuditor(): boolean {
    return this.roleCode === UserRoleCode.AUDITOR;
  }

  static fromAttrs(attrs: SessionAttrs): Session {
    if (!attrs) {
      return new Session();
    }

    return new Session(
      attrs.token,
      attrs.userId,
      attrs.roleCode,
      attrs.seasonId,
      attrs.timeOfExpiration,
      attrs.user,
    );
  }

  isValid(): boolean {
    return this.token !== '';
  }
}
