import {Component, OnDestroy} from '@angular/core';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {AppStateService} from '../../state/app-state.service';
import {Subject} from 'rxjs';
import {Session} from '../../auth/session/session';
import {filter, takeUntil} from 'rxjs/operators';

interface NavItem {
  id: string;
  title: string;
  link: string;
  icon?: IconProp;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnDestroy {
  protected currentSession?: Session;

  seasonNavItems: NavItem[] = [];
  managementNavItems: NavItem[] = [];

  protected ngUnsubscribe = new Subject<void>();


  constructor(
    private appState: AppStateService,
  ) {
    this.initStateSubs();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initStateSubs(): void {
    this.appState.currentSession$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(val => val.isValid()),
      )
      .subscribe(session => {
        this.currentSession = session;
        this.initData();
      });
  }

  private initData(): void {
    this.seasonNavItems = [
      {
        id: 'home',
        title: 'Dashboard',
        link: '/home',
        icon: faHome,
      },
      {
        id: 'teams',
        title: 'Teams',
        link: '/teams',
      },
      {
        id: 'games',
        title: 'Games',
        link: '/games',
      },
    ];

    if (this.currentSession?.isAdmin()) {
      this.seasonNavItems.push({
        id: 'imports',
        title: 'Imports',
        link: '/imports',
      });
    }

    this.managementNavItems = [
      {
        id: 'people',
        title: 'People',
        link: '/users',
      },
      {
        id: 'notifications',
        title: 'Notifications',
        link: '/notifications',
      },
      {
        id: 'affiliates',
        title: 'Affiliates',
        link: '/affiliates',
      },
      {
        id: 'clubs',
        title: 'Clubs',
        link: '/clubs',
      },
      {
        id: 'divisions',
        title: 'Divisions',
        link: '/divisions',
      },
      {
        id: 'settings',
        title: 'Settings',
        link: '/settings',
      },
    ];
  }

}
