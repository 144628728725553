import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BootstrapColorVariant} from '../../bootstrap/types';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {ButtonSize, ButtonStyle, ButtonType} from './types';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() label = '';
  @Input() id?: string;
  @Input() icon?: IconProp;
  @Input() buttonType: ButtonType = ButtonType.BUTTON;
  @Input() classes: string[] = [];
  @Input() displayBlock = false;
  @Input() color: BootstrapColorVariant = BootstrapColorVariant.PRIMARY;
  @Input() size?: ButtonSize;
  @Input() style?: ButtonStyle;
  @Input() loading = false;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter<void>();

  readonly faCircleNotch = faCircleNotch;

  getClasses(): string[] {
    const result: string[] = [...this.classes];

    if (this.size === ButtonSize.SMALL) {
      result.push('btn-sm');
    } else if (this.size === ButtonSize.LARGE) {
      result.push('btn-lg');
    }

    if (this.style === ButtonStyle.OUTLINE) {
      result.push('btn-outline-' + this.color);
    } else if (this.style === ButtonStyle.LINK) {
      result.push('btn-link');
      result.push('text-' + this.color);
    } else {
      result.push('btn-' + this.color);
    }

    if (this.displayBlock) {
      result.push('w-100');
    }

    return result;
  }

  onClick(): void {
    if (this.buttonType === ButtonType.BUTTON) {
      this.clicked.emit();
    }
  }
}
