import {getValueFromControl, MaybeAbstractControl} from './ng-rx-form.utils';
import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ValidationErrorKey} from '../shared/field-error/validation-error-key';

export const atLeastOneRequired = (...controlNames: string[]): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const formGroup: UntypedFormGroup = control as UntypedFormGroup;

    for (const controlName of controlNames) {
      const aControl = formGroup?.controls[controlName];
      if (!Validators.required(aControl)) {
        return null;
      }
    }
    return {[ValidationErrorKey.AT_LEAST_ONE_REQUIRED]: true};
  };

export const validDateRange = (fromControlName: string, toControlName: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const formGroup: UntypedFormGroup = control as UntypedFormGroup;
    const fromControl = formGroup?.controls[fromControlName];
    const toControl = formGroup?.controls[toControlName];
    const fromVal = fromControl?.value;
    const toVal = toControl?.value;

    const invalid = fromVal == null || toVal == null || new Date(fromVal).valueOf() > new Date(toVal).valueOf();
    const touched = (toControl?.dirty || toControl?.touched) && (fromControl?.dirty || fromControl?.touched);

    if (touched && invalid) {
      const errorKey = ValidationErrorKey.INVALID_DATE_RANGE;
      fromControl?.setErrors({[errorKey]: true});
      toControl?.setErrors({[errorKey]: true});
      return {[errorKey]: true};
    }

    fromControl?.setErrors(null);
    toControl?.setErrors(null);
    return null;
  };

export const mustMatch = (primaryControlName: string, matchingControlName: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const formGroup: UntypedFormGroup = control as UntypedFormGroup;
    const primaryControl = formGroup.controls[primaryControlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if ((matchingControl.dirty || matchingControl.touched) && primaryControl.value !== matchingControl.value) {
      matchingControl.setErrors({[ValidationErrorKey.MUST_MATCH]: true});
      return {[ValidationErrorKey.MUST_MATCH]: 'Fields must match.'};
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };


export const mustNotMatch = (primaryControlName: string, matchingControlName: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const formGroup: UntypedFormGroup = control as UntypedFormGroup;
    const primaryControl = formGroup.controls[primaryControlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if ((matchingControl.dirty || matchingControl.touched)
      && primaryControl.value != null
      && primaryControl.value === matchingControl.value) {
      matchingControl.setErrors({[ValidationErrorKey.MUST_NOT_MATCH]: true});
      return {[ValidationErrorKey.MUST_NOT_MATCH]: 'Fields cannot match.'};
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };

/**
 * The field is required if the other field's value strict equals the given test value.
 */
export const requiredIfOtherValueMatches = (otherCtrlProvider: () => MaybeAbstractControl, test: any): ValidatorFn =>
  (control: MaybeAbstractControl): { [key: string]: any } | null => {
    const other = otherCtrlProvider();
    const error = {[ValidationErrorKey.REQUIRED]: 'Field required.'};
    if (control && other && other.value === test) {
      if (control.value == null) {
        return error;
      }
      if ((Array.isArray(control.value) || typeof control.value === 'string') && control.value.length === 0) {
        return error;
      }
    }
    return null;
  };

export const requiredIfTrue = (trueCheckFn: () => boolean, msg?: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (control && trueCheckFn() && (control.value == null || control.value === '')) {
      return {[ValidationErrorKey.REQUIRED]: msg ? msg : 'Required.'};
    }
    return null;
  };

/**
 * Required if other control has a non-null, non-empty value.
 */
export const requiredIfOtherCtrlHasValue = (otherCtrlProvider: () => MaybeAbstractControl,
                                            trueCheckFn?: () => boolean,
                                            msg?: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (trueCheckFn != null && !trueCheckFn()) {
      return null;
    }

    const otherCtrlValue = getValueFromControl(otherCtrlProvider());
    if (notNullOrEmptyString(otherCtrlValue) && nullOrEmptyString(control.value)) {
      return {[ValidationErrorKey.REQUIRED]: msg ? msg : 'Required.'};
    }
    return null;
  };

const notNullOrEmptyString = (val: any): boolean => val != null && val !== '';
const nullOrEmptyString = (val: any): boolean => !notNullOrEmptyString(val);
