import {Component, OnInit} from '@angular/core';
import {AppStateService} from '../../state/app-state.service';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent implements OnInit {

  constructor(
    private appState: AppStateService,
  ) {
  }

  ngOnInit(): void {
    this.initData();
  }

  private initData(): void {
    this.appState.findSeasons();
    this.appState.findTeamRoles();
  }
}
