<div class="modal-header">
  <h4 class="modal-title" role="heading" aria-level="4">{{title}}</h4>
  <button type="button"
          class="btn-close"
          aria-label="Close"
          (click)="activeModal.dismiss()">
  </button>
</div>

<div class="modal-body">
  <p class="modal-message">
    {{message}}
  </p>
  @if (messageSecondary) {
    <p class="modal-message">
      {{messageSecondary}}
    </p>
  }
</div>

<div class="modal-footer">
  @if (showCancel) {
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="activeModal.dismiss()">
      {{cancelLabel}}
    </button>
  }
  <button type="button"
          class="btn"
          [ngClass]="{'btn-primary': !danger, 'btn-danger': danger}"
          (click)="activeModal.close()">
    {{confirmLabel}}
  </button>
</div>
