import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

/**
 * Custom NgbDateAdapter for ng-bootstrap DatePickers using dayjs to parse/format dates to/from the model.
 * https://ng-bootstrap.github.io/#/components/datepicker/overview#date-model
 *
 *  Add to the appropriate @Module:
 *    providers: [
 *      {
 *        provide: NgbDateAdapter,
 *        useFactory: () => new NgbDateCustomParserFormatter('MM/DD/YYYY')
 *      }
 *    ]
 */
export class NgbCustomDateAdapter extends NgbDateAdapter<string> {

  constructor(private dateFormat: string) {
    super();
  }

  fromModel(value: string | null): NgbDateStruct | null {
    if (value != null) {
      const date = dayjs(value, this.dateFormat);

      if (date.isValid()) {
        return {
          day: date.date(),
          month: date.month() + 1,
          year: date.year()
        };
      }
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (date === null) {
      return null;
    }

    const d = dayjs().year(date.year).month(date.month - 1).date(date.day);
    return d.isValid() ? d.format(this.dateFormat) : null;
  }
}
