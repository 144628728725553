import {Pipe, PipeTransform} from '@angular/core';
import {UserRoleCode} from '../../users/model/user-role-code';
import {UserRoleCodeUtils} from '../../users/model/user-role-code-utils';

@Pipe({
  name: 'userRoleCodeLabel'
})
export class UserRoleCodeLabelPipe implements PipeTransform {

  transform(roleCode: UserRoleCode | string): string {
    return UserRoleCodeUtils.toLabel(roleCode);
  }

}
