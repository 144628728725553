import {Subscription} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {AbstractControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';

export type MaybeAbstractControl = AbstractControl | null | undefined;
export type AbstractControlProvider = () => MaybeAbstractControl;
export type MaybeFormGroup = UntypedFormGroup | null | undefined;

export const formHasValues = (formGroup: MaybeFormGroup): boolean => {
  if (formGroup == null) {
    return false;
  }

  return Object.values(formGroup.controls).some(({value}) => !!value || value === 0);
};

export const updateAllControlsValueAndValidity = (formGroup: MaybeFormGroup): void => {
  if (formGroup != null) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key)?.updateValueAndValidity({emitEvent: false});
    });
  }
};

/**
 * Get the AbstractControl's value. Takes a control object, or its string key in the given
 * {@link MaybeFormGroup}.
 */
export const getControlValue = (formGroup: MaybeFormGroup, ctrl: MaybeAbstractControl | string): any => {
  if (formGroup == null) {
    return null;
  }

  let ctrlVal = ctrl;
  if (typeof ctrlVal === 'string') {
    ctrlVal = formGroup.get(ctrlVal);
  }
  return getValueFromControl(ctrlVal);
};

export const getControlNumberValue = (formGroup: MaybeFormGroup, ctrl: MaybeAbstractControl | string): number | undefined => {
  const ctrlVal = getControlValue(formGroup, ctrl);
  if (ctrlVal != null && isFinite(ctrlVal)) {
    return Number(ctrlVal);
  }
  return;
};

export const nullCtrlValueError = () => new Error('Control value was null or ' +
  'undefined. If this is OK, then use the null-safe version of this method instead.');

export const getNonNullControlValue = (formGroup: MaybeFormGroup, ctrl: MaybeAbstractControl | string): string => {
  if (formGroup == null || ctrl == null) {
    throw new Error('Form group and ctrl required.');
  }
  const value = getControlValue(formGroup, ctrl);
  if (value == null) {
    throw nullCtrlValueError();
  }
  return value;
};

export const getValueFromControl = (ctrl: MaybeAbstractControl): any => ctrl ? ctrl.value : null;

export const getNonNullValueFromControl = (ctrl: MaybeAbstractControl): string => {
  if (ctrl == null) {
    throw new Error('ctrl required.');
  }
  const value = getValueFromControl(ctrl);
  if (value == null) {
    throw nullCtrlValueError();
  }
  return value;
};

/**
 * Set the value of an {@link AbstractControl} contained in the given {@link FormGroup}. Handles
 * any null checks. Returns the {@link AbstractControl} if it exists and the value was successfully
 * set, otherwise null.
 *
 * Optionally set the control as dirty and touched as well.
 */
export const setControlValueByKey = (formGroup: MaybeFormGroup, ctrlKey: string, value: any,
                                     setDirty?: boolean, refreshValidity?: boolean): AbstractControl | null => {
  if (formGroup == null || ctrlKey == null) {
    return null;
  }
  return setControlValue(formGroup.get(ctrlKey), value, setDirty, refreshValidity);
};

/**
 * Set the given control value. Handles any null checks. Returns the control if value was
 * successfully set.
 *
 * Optionally set the control as dirty and touched as well.
 */
export const setControlValue = (ctrl: MaybeAbstractControl, value: any, setDirty?: boolean,
                                refreshValidity?: boolean): AbstractControl | null => {
  if (ctrl == null) {
    return null;
  }

  if (ctrl) {
    ctrl.setValue(value);
    if (setDirty) {
      ctrl.markAsTouched();
      ctrl.markAsDirty();
    }
    if (refreshValidity) {
      ctrl.updateValueAndValidity();
    }
    return ctrl;
  }
  return null;
};

export const patchValue = (formGroup: MaybeFormGroup, patch: any): MaybeFormGroup => {
  if (formGroup != null && patch != null) {
    formGroup.patchValue(patch);
    return formGroup;
  }
  return null;
};

/**
 * Set Validators for the given control and updates the validity for that control. Handles any null checks. Returns the control
 * if validators were successfully set.
 */
export const setControlValidators = (ctrl: MaybeAbstractControl, validators: ValidatorFn[]): MaybeAbstractControl => {
  if (ctrl == null) {
    return null;
  }

  if (ctrl) {
    ctrl.setValidators(validators);
    ctrl.updateValueAndValidity();
    return ctrl;
  }
  return null;
};

/**
 * Clears Validators for the given control and updates the validity for that control. Handles any null checks. Returns the control
 * if validators were successfully set.
 */
export const clearControlValidators = (ctrl: MaybeAbstractControl): MaybeAbstractControl => {
  if (ctrl == null) {
    return null;
  }

  if (ctrl) {
    ctrl.clearValidators();
    ctrl.updateValueAndValidity();
    return ctrl;
  }
  return null;
};

/**
 * Perform the given actions if the form control with the given name is present in the given
 * form group.
 */
export const formControlDoIfPresent = (group: MaybeFormGroup, ctrlName: string, actions: (ctrl: AbstractControl) => void): void => {
  if (group && ctrlName && actions) {
    formControlDo(group.get(ctrlName), actions);
  }
};

/**
 * Perform the given action(s) on the given form control, if it is present.
 *
 * @param ctrl
 * @param actions callback
 */
export const formControlDo = (ctrl: MaybeAbstractControl, actions: (ctrl: AbstractControl) => void): void => {
  if (ctrl) {
    actions(ctrl);
  }
};

export const doOnDistinctValueChange = (ctrl: MaybeAbstractControl,
                                        actions: (ctrlValue: any) => void,
                                        customFilter?: (ctrlValue: any) => boolean): void => {
  formControlDo(ctrl, c => {
    c.valueChanges.pipe(
      distinctUntilChanged(),
      filter(ctrlValue => customFilter ? customFilter(ctrlValue) : true)
    ).subscribe(
      next => actions(next)
    );
  });
};

export const doOnDistinctStatusChange = (ctrl: MaybeAbstractControl,
                                         actions: (status: any) => void,
                                         customFilter?: (status: any) => boolean): void => {
  formControlDo(ctrl, c => {
    c.statusChanges.pipe(
      distinctUntilChanged(),
      filter(status => customFilter ? customFilter(status) : true)
    ).subscribe((status: any) => actions(status)
    );
  });
};

/**
 * Disable a secondary control based on some criteria whenever there is a unique change in the
 * primary control's value. Optionally, do something after the secondary control is enabled
 * as well.
 *
 * @param  primaryCtrl primary AbstractControl
 * @param  secondaryCtrl secondary AbstractControl
 * @param  disableCriteria - Required criteria to check before disabling the secondary control.
 * @param  onEnable - Optional function to execute after enabling the secondary control.
 * @returns new subscription to the primary ctrl valueChanges Observable, if it exists.
 */
export const createDisabledFieldDependency = (
  primaryCtrl: MaybeAbstractControl,
  secondaryCtrl: MaybeAbstractControl,
  disableCriteria: (primaryCtrlValue: any) => boolean,
  onEnable?: (primaryCtrlValue: any) => void
): Subscription | null => {
  if (primaryCtrl) {
    return primaryCtrl.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe((val: any) => {
      if (secondaryCtrl) {
        if (disableCriteria(val)) {
          secondaryCtrl.reset();
          secondaryCtrl.disable();
        } else {
          secondaryCtrl.enable();
          if (onEnable) {
            onEnable(val);
          }
        }
      }
    });
  }
  return null;
};

/**
 * Create a bi-direction disabled field dependency on two controls. If any value is present in one
 * of the controls, then the other one is disabled. Returns an array of both subscriptions.
 *
 * @param ctrl1 first MaybeAbstractControl
 * @param ctrl2 second MaybeAbstractControl
 * @returns subscription
 */
export const createBiDirectionalDisabledFieldDependency = (ctrl1: MaybeAbstractControl,
                                                           ctrl2: MaybeAbstractControl
): Subscription[] => [
  createDisabledFieldDependency(ctrl1, ctrl2, primaryCtrlValue => Boolean(primaryCtrlValue)
  ) as Subscription,
  createDisabledFieldDependency(ctrl2, ctrl1, primaryCtrlValue => Boolean(primaryCtrlValue)
  ) as Subscription
];

/**
 * Determines whether a FormGroup exists and contains a control of a specified name.
 */
export const hasControl =
  (formGroup: MaybeFormGroup, controlName: string): boolean => formGroup != null && formGroup.get(controlName) != null;
