import {Component, Input} from '@angular/core';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {UntypedFormGroup} from '@angular/forms';
import {ValidationMessage} from '../field-error/validation-message';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
})
export class DatepickerComponent {
  @Input() form?: UntypedFormGroup;
  @Input() id = '';
  @Input() controlName = '';
  @Input() label = '';
  @Input() required = false;
  @Input() linkedControls: string[] = [];
  @Input() fieldErrorMessages: ValidationMessage[] = [];
  @Input() submitted = false;
  @Input() placeholder = 'mm/dd/yyyy';

  readonly faCalendarAlt = faCalendarAlt;
}
