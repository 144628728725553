import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private static readonly HTTP_STATUS_UNAUTHORIZED = 401;
  private static readonly AUTH_HEADER = 'Auth-Token';
  private static readonly RETRY_COUNT = 1;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const authRequest = req.clone({
      setHeaders: {
        [AuthHttpInterceptor.AUTH_HEADER]: token
      }
    });
    return next.handle(authRequest)
      .pipe(
        retry(AuthHttpInterceptor.RETRY_COUNT),
        map(this.catchLegacyApiError),
        catchError((err) => {
            if (err.status === AuthHttpInterceptor.HTTP_STATUS_UNAUTHORIZED) {
              this.authService.logout();
              this.navigateToLogin();
            }
            return throwError(err);
          }
        )
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private catchLegacyApiError(response: any): any {
    // Old NSM APIs return errors as strings in SUCCESS responses
    if (response?.body?.error) {
      throw new Error(response.body.error);
    }
    return response;
  }

  private navigateToLogin(): void {
    this.router.navigate(
      ['/login'], {
        queryParams: {
          redirectUrl: this.router.routerState.snapshot.url
        }
      }
    );
  }
}
