import {Injectable} from '@angular/core';
import {Season} from '../seasons/model/season';
import {Observable} from 'rxjs';
import {StateService} from '../shared/service/state.service';
import {SeasonService} from '../seasons/season.service';
import {shareReplay, tap} from 'rxjs/operators';
import {Session} from '../auth/session/session';
import {SessionService} from '../auth/session/session.service';
import {clone} from 'lodash-es';
import {TeamRole} from '../users/model/team-role';
import {TeamRoleService} from '../users/service/team-role.service';

interface AppState {
  currentSession: Session;
  seasons: Season[];
  teamRoles: TeamRole[];
}

const initialState: AppState = {
  currentSession: new Session(),
  seasons: [],
  teamRoles: [],
};

@Injectable({
  providedIn: 'root'
})
export class AppStateService extends StateService<AppState> {

  currentSession$: Observable<Session> =
    this.select((state) => state.currentSession);

  seasons$: Observable<Season[]> =
    this.select((state) => state.seasons);

  selectedSeason$: Observable<Season | undefined> = this.select((state) =>
    state.seasons.find((item) => item.seasonId === state.currentSession.seasonId))
    .pipe(
      // Multicast to prevent multiple executions due to multiple subscribers
      shareReplay({refCount: true, bufferSize: 1}),
    );

  teamRoles$: Observable<TeamRole[]> =
    this.select((state) => state.teamRoles);

  constructor(
    private seasonService: SeasonService,
    private sessionService: SessionService,
    private teamRoleService: TeamRoleService,
  ) {
    super(initialState);
  }

  setCurrentSession(currentSession: Session): void {
    this.setState({
      currentSession: clone(currentSession)
    });
  }

  setSeasons(seasons: Season[]): void {
    this.setState({
      seasons: [...seasons]
    });
  }

  findCurrentSession(): void {
    this.sessionService.findCurrent()
      .pipe(
        tap((session: Session) => this.setCurrentSession(session)),
      ).subscribe();
  }

  findSeasons(): void {
    this.seasonService.findAll()
      .pipe(
        tap(seasons => this.setSeasons(seasons)),
      ).subscribe();
  }

  updateSelectedSeason(seasonId: number): void {
    this.sessionService.update(seasonId).subscribe(session => {
      this.setCurrentSession(session);
    });
  }

  setTeamRoles(teamRoles: TeamRole[]): void {
    this.setState({
      teamRoles: [...teamRoles]
    });
  }

  findTeamRoles(): void {
    this.teamRoleService.findAll()
      .pipe(
        tap(teamRoles => this.setTeamRoles(teamRoles)),
      ).subscribe();
  }
}
