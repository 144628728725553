import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as dayjs from 'dayjs';
import {OpUnitType} from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as utc from 'dayjs/plugin/utc';

// Formats: https://day.js.org/docs/en/display/format
export enum DateFormat {
  MMDDYYYY_SLASH = 'MM/DD/YYYY',
  ISO_8601 = 'YYYY-MM-DD',
  SHORT_DATE = 'L',
  MEDIUM_DATE = 'll',
  LONG_DATE = 'LL',
  SHORT_DATETIME = 'L LT',
  MEDIUM_DATETIME = 'll, LT',
  LONG_DATETIME = 'LL, LT',
  LONG_DATETIME_WITH_DAY = 'LLLL',
  SHORT_TIME = 'LT',
  LONG_TIME = 'LTS',
  TIMESTAMP = 'YYYYMMDDHHmmss',
}

export class DateUtils {

  static format(date: Date | null | undefined, format?: string): string {
    if (date == null) {
      return '';
    }

    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    return dayjs(date).format(format);
  }

  static formatUtc(date: Date | null | undefined, format?: string): string {
    if (date == null) {
      return '';
    }

    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    return dayjs.utc(date).format(format);
  }

  static parseDate(date: string, format?: string): Date {
    dayjs.extend(customParseFormat);
    return dayjs(date, format).toDate();
  }

  static parseUtcDate(date: Date): Date {
    dayjs.extend(utc);
    return dayjs.utc(date).toDate();
  }

  static isSame(date1: string, date2: string, unit: OpUnitType = 'day'): boolean {
    return dayjs(date1).isSame(dayjs(date2), unit);
  }

  static isAfter(date1: string, date2: string, unit: OpUnitType = 'day'): boolean {
    return dayjs(date1).isAfter(dayjs(date2), unit);
  }

  static isBefore(date1: string, date2: string, unit: OpUnitType = 'day'): boolean {
    return dayjs(date1).isBefore(dayjs(date2), unit);
  }

  static get currentYear(): number {
    return new Date().getFullYear();
  }

  static getCurrentDateISOString(): string {
    return new Date().toISOString().split('T')[0];
  }
}
