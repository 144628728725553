import {Component} from '@angular/core';
import {AlertService} from '../alert.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts-container.component.html',
})
export class AlertsContainerComponent {

  constructor(
    public alertService: AlertService,
  ) {
  }
}
