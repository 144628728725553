export enum MobileOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  WINDOWS_PHONE = 'Windows Phone',
  UNKNOWN = 'Unknown',
}

export class BrowserUtils {

  /**
   * Determines the mobile operating system.
   *
   * @returns MobileOS
   */
  static getMobileOS(): MobileOS {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return MobileOS.WINDOWS_PHONE;
    }

    if (/android/i.test(userAgent)) {
      return MobileOS.ANDROID;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return MobileOS.IOS;
    }

    return MobileOS.UNKNOWN;
  }
}
