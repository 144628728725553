import {UserRoleCode} from './user-role-code';

export class UserRoleCodeUtils {

  static toLabel(code?: UserRoleCode | string): string {
    if (code == null) {
      return '';
    }

    switch (code) {
      case UserRoleCode.ADMIN: {
        return 'Admin';
      }
      case UserRoleCode.AUDITOR: {
        return 'Auditor';
      }
      case UserRoleCode.GUEST: {
        return 'Guest';
      }
      case UserRoleCode.USER: {
        return 'User';
      }
      default: {
        console.warn('Unhandled UserRoleCode: ' + code);
        return '';
      }
    }
  }
}
