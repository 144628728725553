import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Season} from '../../seasons/model/season';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {AppStateService} from '../../state/app-state.service';
import {Session} from '../../auth/session/session';

@Component({
  selector: 'app-season-picker',
  templateUrl: './season-picker.component.html',
})
export class SeasonPickerComponent {
  readonly currentSession$: Observable<Session>;
  seasons$: Observable<Season[]>;
  selectedSeason$: Observable<Season | undefined>;

  readonly faPlus = faPlus;

  constructor(
    private appState: AppStateService,
  ) {
    this.currentSession$ = appState.currentSession$;
    this.seasons$ = appState.seasons$;
    this.selectedSeason$ = appState.selectedSeason$;
  }

  updateSelectedSeason(seasonId: number): void {
    this.appState.updateSelectedSeason(seasonId);
  }
}
