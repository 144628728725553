import {Injectable} from '@angular/core';
import {ApiResponse} from '../../shared/entity/model/api-response';
import {TeamRole} from '../model/team-role';
import {EntityService} from '../../shared/entity/entity.service';
import {HttpClient} from '@angular/common/http';
import {RuntimeConfig} from '../../../runtime-config';
import {AlertService} from '../../shared/ui-alert/alert.service';
import {orderBy} from 'lodash-es';


export interface TeamRoleResponse extends ApiResponse {
  teamRole: TeamRole;
}

export interface TeamRolesResponse extends ApiResponse {
  teamRoles: TeamRole[];
}

@Injectable({
  providedIn: 'root'
})
export class TeamRoleService extends EntityService<TeamRole, TeamRoleResponse, TeamRolesResponse> {

  constructor(
    httpClient: HttpClient,
    runtimeConfig: RuntimeConfig,
    alertService: AlertService,
  ) {
    super(httpClient, runtimeConfig, alertService, {
      apiPath: '/api/teamRoles',
      collectionRoute: '', // none
      idAttr: 'teamRoleId',
      allowCreate: false,
      allowDelete: false,
      parseItemResponse: response => response.teamRole,
      parseListResponse: response => response.teamRoles,
      sortList: (items: TeamRole[]) => orderBy(items, ['label'], ['desc']),
      entityToString: item => item.label,
      singular: 'Team Role',
      plural: 'Team Roles',
    });
  }
}
