import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  private readonly fakeDate = '01/01/1900 ';

  constructor(public datePipe: DatePipe) {
  }

  transform(value: Date | string | number | null | undefined,
            format?: string,
            locale?: string): string | null {
    return this.datePipe.transform(value != null ? this.fakeDate + value : value, format, locale);
  }
}
