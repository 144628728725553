<button class="btn"
        [ngClass]="getClasses()"
        [type]="buttonType"
        [attr.aria-label]="label"
        [disabled]="disabled || loading"
        (click)="onClick()">
  <span role="status">
    @if (loading) {
      <span class="btn__loading">
        <fa-icon [icon]="faCircleNotch"
                 [animation]="'spin'"
                 title="Loading..."
                 aria-hidden="true"></fa-icon>
        <span class="sr-only">Loading...</span>
      </span>
    }
  </span>
  @if (icon && !loading) {
    <fa-icon [icon]="icon" aria-hidden="true"></fa-icon>
  }
  {{label}}
</button>
