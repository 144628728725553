import {HttpClient} from '@angular/common/http';
import {RuntimeConfig} from '../../../runtime-config';
import {Observable, pipe, UnaryFunction} from 'rxjs';
import {AlertService} from '../ui-alert/alert.service';
import {EntityConfig} from './model/entity-config';
import {catchError, map, tap} from 'rxjs/operators';
import {getErrorMessage, transformError} from '../../utils/error-utils';
import {ApiResponse} from './model/api-response';
import {BootstrapColorVariant} from '../bootstrap/types';

export abstract class EntityService<T, I extends ApiResponse, L extends ApiResponse> {

  protected constructor(
    protected readonly httpClient: HttpClient,
    protected readonly runtimeConfig: RuntimeConfig,
    private readonly alertService: AlertService,
    public readonly config: EntityConfig<T, I, L>,
  ) {
  }

  findAll(): Observable<T[]> {
    return this.httpClient.get<L>(`${this.baseUrl}`)
      .pipe(this.listPipe);
  }

  protected get itemPipe(): UnaryFunction<Observable<I>, Observable<T>> {
    return pipe(
      map(this.config.parseItemResponse),
      catchError(transformError)
    );
  }

  protected get listPipe(): UnaryFunction<Observable<L>, Observable<T[]>> {
    return pipe(
      map(this.config.parseListResponse),
      map(this.config.sortList),
      catchError(transformError)
    );
  }

  findById(id: number): Observable<T> {
    return this.httpClient.get<I>(
      `${this.baseUrl}/${id}`)
      .pipe(this.itemPipe);
  }

  create(entity: T): Observable<T> {
    if (!this.config.allowCreate) {
      throw new Error('Create operation not supported.');
    }

    return this.httpClient.post<I>(`${this.baseUrl}`, entity)
      .pipe(
        map(this.config.parseItemResponse),
        tap((item: T) => this.alertSuccess(`${this.config.singular} created: "${this.config.entityToString(item)}"`)),
        catchError(error => {
          this.alertError(getErrorMessage(error));
          return transformError(error);
        })
      );
  }

  update(entity: T): Observable<T> {
    return this.httpClient.put<I>(
      `${this.baseUrl}/${this.getId(entity)}`, entity)
      .pipe(
        map(this.config.parseItemResponse),
        tap((item: T) => this.alertSuccess(`${this.config.singular} updated: "${this.config.entityToString(item)}"`)),
        catchError(error => {
          this.alertError(getErrorMessage(error));
          return transformError(error);
        })
      );
  }

  delete(entity: T): Observable<ApiResponse> {
    if (!this.config.allowDelete) {
      throw new Error('Delete operation not supported.');
    }

    return this.httpClient.delete<ApiResponse>(`${this.baseUrl}/${this.getId(entity)}`)
      .pipe(
        tap(() => this.alertSuccess(`${this.config.singular} deleted: "${this.config.entityToString(entity)}"`)),
        catchError(error => {
          this.alertError(getErrorMessage(error));
          return transformError(error);
        })
      );
  }

  protected get baseUrl(): string {
    return `${this.runtimeConfig.baseUrl}${this.config.apiPath}`;
  }

  isNew(entity?: T): boolean {
    return !this.getId(entity);
  }

  entityToString(entity?: T): string {
    return entity != null ? this.config.entityToString(entity) : '';
  }

  getId(entity?: T): number | undefined {
    return entity != null ? entity[this.config.idAttr as keyof T] as unknown as number : undefined;
  }

  public alertSuccess(message: string): void {
    this.alertService.show({
      text: message,
      type: BootstrapColorVariant.SUCCESS,
      selfClosing: true,
    });
  }

  public alertError(message: string): void {
    this.alertService.show({
      text: message,
      type: BootstrapColorVariant.DANGER,
      dismissible: true,
      selfClosing: false,
    });
  }
}
