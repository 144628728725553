import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RuntimeConfig} from '../../../runtime-config';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {transformError} from '../../utils/error-utils';
import {Session, SessionAttrs} from './session';

interface SessionResponse {
  session: SessionAttrs;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private httpClient: HttpClient,
              private config: RuntimeConfig) {
  }

  findCurrent(): Observable<Session> {
    return this.httpClient.get<SessionResponse>(
      `${this.config.baseUrl}/api/sessions`)
      .pipe(
        map((response: SessionResponse) => Session.fromAttrs(response.session)),
        catchError(transformError)
      );
  }

  update(seasonId: number): Observable<Session> {
    interface SessionRequest {
      seasonId: number;
    }

    const sessionRequest: SessionRequest = {seasonId};

    return this.httpClient.put<SessionResponse>(`${this.config.baseUrl}/api/sessions`, sessionRequest)
      .pipe(
        map((response: SessionResponse) => Session.fromAttrs(response.session)),
        catchError(transformError)
      );
  }
}
