import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UiAlert} from '../../shared/ui-alert/ui-alert';
import {mustMatch} from '../../utils/ng-rx-form-validators';
import {ValidationErrorKey} from '../../shared/field-error/validation-error-key';
import {RuntimeTarget} from '../../../runtime-target';
import {ResetPasswordRequest} from '../../users/model/reset-password-request';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../users/service/user.service';
import {User} from '../../users/model/user';
import {catchError, filter, finalize, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ApiResponse} from '../../shared/entity/model/api-response';
import {BootstrapColorVariant} from '../../shared/bootstrap/types';
import {ButtonType} from '../../shared/form/button/types';

export enum FormControlName {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  PASSWORD_CONFIRM = 'PASSWORD_CONFIRM',
}

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnInit {
  token = '';
  user?: User;

  form!: UntypedFormGroup;
  alert?: UiAlert;
  loading = false;
  submitted = false;
  succeeded = false;

  title = '';
  passwordLabel = '';
  passwordConfirmLabel = '';
  buttonLabel = '';

  readonly FormControlName = FormControlName;
  readonly ValidationErrorKey = ValidationErrorKey;
  readonly ButtonType = ButtonType;

  constructor(private formBuilder: UntypedFormBuilder,
              public userService: UserService,
              public runtimeTarget: RuntimeTarget,
              private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.initText();
    this.setTokenFromRequest();
    this.fetchData();
  }

  initText() {
    this.title = 'Reset Password';
    this.passwordLabel = 'Password';
    this.passwordConfirmLabel = 'Confirm Password';
    this.buttonLabel = 'Reset Password';
  }

  private setTokenFromRequest() {
    this.token = this.route.snapshot.paramMap.get('token') ?? '';
  }

  private fetchData() {
    this.loading = true;
    this.userService.findByToken(this.token)
      .pipe(
        filter(val => val != null),
        tap((user: User) => {
          this.user = user;
          this.initForm();
        }),
        finalize(() => this.loading = false),
        catchError(error => {
          this.alert = {
            text: 'This token is invalid.',
            type: BootstrapColorVariant.DANGER,
          };
          return of({});
        }),
      )
      .subscribe();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
        [FormControlName.EMAIL]: [{value: this.user?.email, disabled: true}, [Validators.required]],
        [FormControlName.PASSWORD]: [null, [Validators.required, Validators.maxLength(50)]],
        [FormControlName.PASSWORD_CONFIRM]: [null, [Validators.required, Validators.maxLength(50)]],
      },
      {
        validators: [mustMatch(FormControlName.PASSWORD, FormControlName.PASSWORD_CONFIRM)]
      }
    );
  }

  submit() {
    this.alert = undefined;
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    this.userService.resetPassword(this.token, this.getResetPasswordRequest())
      .pipe(
        filter(val => val != null),
        tap((response: ApiResponse) => {
          if (response.success) {
            this.succeeded = true;
            this.alert = {
              text: `Your password has been reset.`,
              type: BootstrapColorVariant.SUCCESS,
            };
          } else {
            this.alert = {
              text: `Your password could not be reset: ${response.error}`,
              type: BootstrapColorVariant.DANGER,
            };
          }
        }),
        finalize(() => {
          this.loading = false;
          this.submitted = false;
        }),
        catchError(error => {
          this.alert = {
            text: `Your password could not be reset: ${error}`,
            type: BootstrapColorVariant.DANGER,
          };
          return of({});
        }),
      )
      .subscribe();
  }

  private getResetPasswordRequest(): ResetPasswordRequest {
    return {
      newPassword: this.form.get(FormControlName.PASSWORD)?.value ?? '',
      verifyPassword: this.form.get(FormControlName.PASSWORD_CONFIRM)?.value ?? '',
      appKey: this.runtimeTarget.appKey,
    };
  }
}
