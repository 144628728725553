import {Component, Input, OnChanges} from '@angular/core';
import {faSort, faSortDown, faSortUp, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {SortDir} from '../page-and-sort';

@Component({
  selector: 'app-header-sort-icon',
  templateUrl: './header-sort-icon.component.html',
})
export class HeaderSortIconComponent implements OnChanges {
  @Input() columnId = '';
  @Input() sortColumnId?: string;
  @Input() sortDir?: SortDir;

  iconDef: IconDefinition = faSort;
  altText = 'Click to sort';

  ngOnChanges(): void {
    this.setIconDef();
  }

  setIconDef(): void {
    if (this.sortColumnId === this.columnId && this.sortDir != null) {
      if (this.sortDir === SortDir.ASC) {
        this.iconDef = faSortUp;
      } else {
        this.iconDef = faSortDown;
      }
    } else {
      this.iconDef = faSort;
    }
  }
}
