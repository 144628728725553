import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  @Input() title = 'Confirm';
  @Input() message = '';
  @Input() messageSecondary = '';
  @Input() confirmLabel = 'OK';
  @Input() cancelLabel = 'Cancel';
  @Input() showCancel = true;
  @Input() danger = false;

  constructor(public activeModal: NgbActiveModal) {
  }
}
