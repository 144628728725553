import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DataTableComponent, DataTableRow} from '../data-table/data-table.component';
import {StringUtils} from '../../utils/string-utils';
import {CollectionFilter} from '../entity/collection-filter/types';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PageAndSort} from '../data-table/page-and-sort';
import {valueChanged} from '../../utils/change-utils';

@Component({
  selector: 'app-collection-view',
  templateUrl: './collection-view.component.html',
})
export class CollectionViewComponent implements OnInit, OnChanges {
  // TODO: Make generic -- use more specific types

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items: any[] = [];
  @Input() nameSingular = 'Item';
  @Input() namePlural = 'Items';
  @Input() emptyText = 'No data found.';
  @Input() filterEmptyText = 'No records match this search.';
  @Input() pageSize: number = DataTableComponent.DEFAULT_PAGE_SIZE;
  @Input() smallTable = false;
  @Input() pageAndSortViaRouter = true;
  @Input() loading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() createDataTableRowFn!: (item: any) => DataTableRow;
  @Input() allowSearch = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() itemMatchesSearchFn?: (item: any, searchValue: string) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() collectionFilter?: CollectionFilter<any>;

  pageAndSort: PageAndSort = {page: DataTableComponent.DEFAULT_CURRENT_PAGE};

  filteredRows: DataTableRow[] = [];
  searchValue?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.pageAndSortViaRouter) {
      this.activatedRoute.queryParams.subscribe((params: Params): void => {
        this.pageAndSort = {
          page: +params.page ? +params.page : 1,
          sort: params.sort,
          sortDir: params.sortDir,
        };
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (valueChanged(changes.items)) {
      this.updateFilteredRows();
    }
  }

  private updateFilteredRows(): void {
    this.filteredRows = this.items
      .filter(item => this.matchesSearchAndFilter(item))
      .map(item => this.createDataTableRowFn(item));
  }

  hasData(): boolean {
    return this.items?.length > 0;
  }

  hasFilteredData(): boolean {
    return this.filteredRows?.length > 0;
  }

  // Search
  onSearchValueChanged(value: string): void {
    this.searchValue = value;
    this.updateFilteredRows();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected matchesSearchAndFilter(item: any): boolean {
    return this.matchesFilter(item) && this.matchesSearch(item);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private matchesSearch(item: any): boolean {
    if (StringUtils.isBlank(this.searchValue)) {
      return true;
    }

    const terms = this.searchValue?.split(' ') || [];
    return terms.some(term => this.itemMatchesSearchFn ? this.itemMatchesSearchFn(item, term) : true);
  }


  // Filter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private matchesFilter(item: any): boolean {
    if (this.collectionFilter?.selectedOption == null) {
      return true;
    }
    return this.collectionFilter.filterFn(item, this.collectionFilter.selectedOption);
  }

  onSelectedFilterChange(): void {
    this.updateFilteredRows();
  }


  // Paging and Sorting
  onPageAndSortChanged(newPageAndSort: PageAndSort): void {
    if (this.pageAndSortViaRouter) {
      this.changePageAndSortViaRouter(newPageAndSort);
    } else {
      this.changePageAndSortInternally(newPageAndSort);
    }
  }

  private changePageAndSortViaRouter(pageAndSort: PageAndSort): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: pageAndSort.page === 0 ? 1 : pageAndSort.page,
        sort: pageAndSort.sort ?? '',
        sortDir: pageAndSort.sortDir ?? '',
      },
      queryParamsHandling: 'merge',
    });
  }

  private changePageAndSortInternally(newPageAndSort: PageAndSort): void {
    this.pageAndSort = {...newPageAndSort};
  }
}
