<section>
  <app-header></app-header>

  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-md-2">
        <app-sidebar></app-sidebar>
      </div>

      <main id="main-content" class="col-md-10">
        <div class="px-4">
          <app-alerts></app-alerts>

          <div aria-live="polite">
            <router-outlet></router-outlet>
          </div>
        </div>
      </main>

    </div>
  </div>

  <app-footer></app-footer>
</section>
