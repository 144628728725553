import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RuntimeConfig} from '../../runtime-config';
import {Season} from './model/season';
import {orderBy, upperCase} from 'lodash-es';
import {EntityService} from '../shared/entity/entity.service';
import {AlertService} from '../shared/ui-alert/alert.service';
import {ApiResponse} from '../shared/entity/model/api-response';

export interface SeasonResponse extends ApiResponse {
  season: Season;
}

export interface SeasonsResponse extends ApiResponse {
  seasons: Season[];
}

@Injectable({
  providedIn: 'root'
})
export class SeasonService extends EntityService<Season, SeasonResponse, SeasonsResponse> {

  constructor(
    httpClient: HttpClient,
    runtimeConfig: RuntimeConfig,
    alertService: AlertService,
  ) {
    super(httpClient, runtimeConfig, alertService, {
      apiPath: '/api/seasons',
      collectionRoute: '/home',
      idAttr: 'seasonId',
      allowCreate: true,
      allowDelete: false,
      parseItemResponse: response => response.season,
      parseListResponse: response => response.seasons,
      sortList: (items: Season[]) => orderBy(items, [item => item.startDate, item => upperCase(item.name)], ['desc', 'asc']),
      entityToString: item => item.name,
      singular: 'Season',
      plural: 'Seasons',
    });
  }
}
