<main id="main-content" class="d-flex justify-content-center">
  <div class="my-5">
    <img class="mb-4 mx-auto d-block"
         src="{{runtimeTarget.theme.logoUrl}}"
         alt="{{runtimeTarget.appName}}"
         height="100px"/>
    <h1 class="text-center text-muted"
        role="heading"
        aria-level="1">
      {{runtimeTarget.consoleName}}
    </h1>
    <h2 class="text-center mb-4"
        role="heading"
        aria-level="2">
      Log In
    </h2>

    <app-alert [uiAlert]="error"></app-alert>

    <div class="card mt-4">
      <form class="card-body"
            [formGroup]="loginForm"
            (ngSubmit)="login()"
            aria-label="Log-in form">

        <div class="mb-3">
          <label for="emailCtrl" class="form-label form-label-required">Email</label>
          <input id="emailCtrl"
                 type="email"
                 class="form-control"
                 inputmode="email"
                 autocomplete="username"
                 [formControlName]="FormControlName.EMAIL"
                 fieldLabel="Email"
                 [submitted]="submitted"
                 appFieldError>
        </div>

        <div class="mb-3">
          <label for="passwordCtrl" class="form-label form-label-required">Password</label>
          <input id="passwordCtrl"
                 type="password"
                 class="form-control"
                 autocomplete="current-password"
                 [formControlName]="FormControlName.PASSWORD"
                 fieldLabel="Password"
                 [submitted]="submitted"
                 appFieldError>
        </div>

        <app-button [buttonType]="ButtonType.SUBMIT"
                    [displayBlock]="true"
                    [label]="'Log In'"
                    [loading]="loading"
                    [disabled]="loading"></app-button>
      </form>
    </div>
  </div>
</main>
