<div class="table-responsive-md">
  <table class="table table-responsive-stack"
         [class.table-nonfluid]="nonfluid"
         [class.table-sm]="small">
    <caption class="visually-hidden">{{caption}}</caption>
    @if (hasRows()) {
      <thead>
      <tr>
        @for (header of rows[0].cells; track header.id; let index = $index) {
          <th scope="col"
              [attr.aria-sort]="getAriaSort(header.id)"
              [class.text-start]="header.align == null || header.align === 'left'"
              [class.text-center]="header.align === 'center'"
              [class.text-end]="header.align === 'right'"
              [class.w-100]="header.expandWidth === true">
            @if (header.sortable) {
              <button
                type="button"
                (click)="header.sortable && onSortClicked(header.id)"
                class="btn btn-link btn-block p-0 m-0"
                [class.text-start]="header.align === 'left' || header.align == null"
                [class.text-center]="header.align === 'center'"
                [class.text-end]="header.align === 'right'">
                  <span class="d-flex justify-content-start align-items-end">
                    <span class="fw-bold">
                      {{header.label}}
                    </span>
                    <span class="ms-2">
                      <app-header-sort-icon
                        [columnId]="header.id"
                        [sortColumnId]="pageAndSort.sort"
                        [sortDir]="pageAndSort.sortDir"></app-header-sort-icon>
                    </span>
                  </span>
              </button>
            } @else {
              {{header.label}}
            }
          </th>
        }
      </tr>
      </thead>
    }

    <tbody class="table-group-divider">
      @for (row of pageRows; track row.id) {
        <tr>
          @for (cell of row.cells; track cell.id) {
            <td [ngClass]="cell.class ?? ''"
                [attr.data-label]="cell.label"
                [class.text-start]="cell.align === 'left'"
                [class.text-center]="cell.align === 'center'"
                [class.text-end]="cell.align === 'right'"
                [class.fw-bold]="cell.bold"
                [style]="{color: cell.color}">
              @if (cell.routerLink) {
                <a [routerLink]="cell.routerLink">{{cell.value}}</a>
              } @else {
                @if (cell.value) {
                  {{cell.value}}
                }

                @if (cell.actionButton) {
                  <app-button
                    [label]="cell.actionButton.label"
                    [icon]="cell.actionButton.icon"
                    [classes]="cell.actionButton.classes ?? []"
                    [color]="cell.actionButton.color ?? BootstrapColorVariant.PRIMARY"
                    [size]="cell.actionButton.size ?? ButtonSize.SMALL"
                    [style]="cell.actionButton.style ?? ButtonStyle.OUTLINE"
                    [loading]="cell.actionButton.loading ?? false"
                    [disabled]="cell.actionButton.disabled ?? false"
                    (clicked)="cell.actionButton.onClick()"></app-button>
                }
              }
            </td>
          }
        </tr>
      }
    </tbody>
  </table>

  @if (rows.length > pageRows.length) {
    <div class="d-flex justify-content-between p-2">
      <ngb-pagination [collectionSize]="rows.length"
                      [page]="pageAndSort.page"
                      [pageSize]="pageSize"
                      [ellipses]="true"
                      [maxSize]="10"
                      (pageChange)="onPageClicked($event)"></ngb-pagination>
    </div>
  }
</div>
