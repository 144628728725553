import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {DataTableComponent} from './data-table/data-table.component';
import {NgbAlertModule, NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {AlertComponent} from './ui-alert/alert/alert.component';
import {SubNavbarComponent} from './sub-navbar/sub-navbar.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ConfirmationModalComponent} from './confirmation-modal/confirmation-modal.component';
import {DateUtcPipe} from './pipe/date-utc.pipe';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {FieldErrorDirective} from './field-error/field-error.directive';
import {AlertsContainerComponent} from './ui-alert/alerts-container/alerts-container.component';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CurrentSeasonIndicatorComponent} from './current-season-indicator/current-season-indicator.component';
import {HeaderSortIconComponent} from './data-table/header-sort-icon/header-sort-icon.component';
import {EntityFormViewComponent} from './entity/form/entity-form-view/entity-form-view.component';
import {ButtonComponent} from './form/button/button.component';
import {RouterModule} from '@angular/router';
import {TimePipe} from './pipe/time.pipe';
import {SearchInputComponent} from './search-input/search-input.component';
import {CollectionViewComponent} from './collection-view/collection-view.component';
import {TeamRolesDropdownComponent} from './team-roles-dropdown/team-roles-dropdown.component';
import {EnumToArrayPipe} from './pipe/enum-to-array.pipe';
import {EmptyStateComponent} from './empty-state/empty-state.component';
import {WinLossRecordPipe} from './pipe/win-loss-record.pipe';
import {UserRoleCodeLabelPipe} from './pipe/user-role-code-label.pipe';

@NgModule({
  declarations: [
    DataTableComponent,
    AlertComponent,
    AlertsContainerComponent,
    EntityFormViewComponent,
    SubNavbarComponent,
    ConfirmationModalComponent,
    DateUtcPipe,
    TimePipe,
    LoadingIndicatorComponent,
    FieldErrorDirective,
    DatepickerComponent,
    CurrentSeasonIndicatorComponent,
    HeaderSortIconComponent,
    ButtonComponent,
    SearchInputComponent,
    CollectionViewComponent,
    TeamRolesDropdownComponent,
    EnumToArrayPipe,
    EmptyStateComponent,
    UserRoleCodeLabelPipe,
    WinLossRecordPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbAlertModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    RouterModule,
  ],
  exports: [
    DataTableComponent,
    AlertComponent,
    EntityFormViewComponent,
    SubNavbarComponent,
    ConfirmationModalComponent,
    DateUtcPipe,
    TimePipe,
    LoadingIndicatorComponent,
    FieldErrorDirective,
    AlertsContainerComponent,
    DatepickerComponent,
    CurrentSeasonIndicatorComponent,
    ButtonComponent,
    SearchInputComponent,
    CollectionViewComponent,
    TeamRolesDropdownComponent,
    EnumToArrayPipe,
    EmptyStateComponent,
    UserRoleCodeLabelPipe,
    WinLossRecordPipe,
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule {
}
